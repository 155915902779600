import React, { useState, useImperativeHandle, useEffect } from "react";


import { GeoJsonLayer, ColumnLayer } from "@deck.gl/layers";
import { Tile3DLayer } from "@deck.gl/geo-layers";

import { WebMercatorViewport } from "@deck.gl/core";

import { _TerrainExtension as TerrainExtension } from "@deck.gl/extensions";

import { getGeo } from "./ParcelData/getGeo";
import { returnBillBoardLayer } from "./LayerGeneration/BillboardLayer/returnBillboardLayer";
import {
  createAvailSummaryLayer,
  createCompSummaryLayer,
} from "./LayerGeneration/BillboardSummaryLayer/returnBillboardSummariesLayer";
import { generateGlobeSummaryLayer } from "./LayerGeneration/GlobeSummaryLayer/generateGlobeSummaryLayer";
import { getMarkerInfo } from "./Pin";

import TestAlert from "./TestAlert";
import polylabel from "polylabel";
import { ScenegraphLayer } from "deck.gl";
import { Box } from "@mui/material";

import { geoContains } from "d3-geo";

import axios from "axios";

import { createCookie, getCookie } from "../Common/Cookies";
import { prepareTestAlert } from "./TestAlert";

import SwipeableTemporaryDrawer from "./Drawer";

import { removeById } from "./InfoCard";

import { getAxios, postAxios } from "./Axios/getAndPostAxios";
import {
  getElevationFromZoom,
  getViewportElevation,
  getZoomFromElevation,
  center,
  sizeThresholds,
} from "./ElevationFunctions/ElevationsFunctions";

import { MapboxOverlay } from "@deck.gl/mapbox";

import { generateBuildingCirclesLayer } from "./LayerGeneration/BuildingCirclesLayer/generateBuildingCirclesLayer";
// Math.gl utilities for 3D transformations & Earth geometry
import { Matrix4, Vector3 } from "@math.gl/core";
import { Ellipsoid } from "@math.gl/geospatial";
import DeckSplitPanel from "./DeckComponents/DeckSplitPanel";
import { generateBuildingPostLayer } from "./LayerGeneration/BuildingPostLayer/generateBuildingPostLayer";

/**
 * Helper to create a "local up" transform at (lat, lon),
 * so we shift the tileset 'metersUp' above ground.
 */
function getLocalUpTransform(latitude, longitude, metersUp) {
  // Convert lat/lon to Earth-Centered Earth-Fixed
  const cartesian = Ellipsoid.WGS84.cartographicToCartesian([
    longitude,
    latitude,
    0,
  ]);

  // "Up" vector is from Earth's center to that point, normalized
  const normalVec = new Vector3(cartesian).normalize();

  // Scale that normal by desired offset
  const offsetVector = normalVec.scale(metersUp);

  // Make a translation matrix for that offset
  return new Matrix4().translate(offsetVector);
}
var TILESET_URL =
  "https://tile.googleapis.com/v1/3dtiles/root.json?key=" +
  process.env.REACT_APP_GoogleMapsAPIKey;

export const COLORS = [
  [254, 235, 226],
  [251, 180, 185],
  [247, 104, 161],
  [197, 27, 138],
  [122, 1, 119],
];

var newAllController = new AbortController();
var newNAController = new AbortController();
var newCitiesController = new AbortController();
var newStateController = new AbortController();

var coords = null;
var coordsLines = null;
var coordsCircles = null;
var clusterVarData = null;

var streetsOnMap = null;
var citiesOnMap = null;
var streetsLinesOnMap = null;
var controller = new AbortController();
var controllerCities = new AbortController();
var controllerEurope = new AbortController();
var controllerNA = new AbortController();
var controllerAll = new AbortController();
var searchedLocations = null;
var viewportElevation = { elevation: -1 };
const overlay = new MapboxOverlay({
  // interleaved: true,

  layers: [],
});

var newNAMaster = { controller: newNAController };
var newCitiesMaster = { controller: newCitiesController };

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

const Decky = React.forwardRef(
  (
    {
      fabRefreshRef,
      filterInputs,
      geometry,
      filters,
      searchResults,
      setSearchResults,
      selectedLocation,
      setSelectedLocation,
      onMarkerClick,
      popupInfo,
      setPopupInfo,

      mapButton,
      contactId,

      reloadOnMove,
      setReloadOnMove,
      elevationFactorState,

      mapRef,
      mapData,
      setMapData,
      sortByProperty,
      initialViewState,
      showParcels,
      setInitialViewState,
      confirmIn,
      surveyId,
      setShowSurveys,
      setShowWait,
      getSelectedLocation,
      setSaveSurveyOpen,
    },
    ref
  ) => {
    controller = new AbortController();
    controllerCities = new AbortController();
    controllerEurope = new AbortController();
    controllerNA = new AbortController();
    controllerAll = new AbortController();
    const [clusterData, setClusterData] = useState({ features: [] });

    const [isLayerAdded, setIsLayerAdded] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(popupInfo ? true : false);
    const [dialogOpen, setDialogOpen] = useState(false);

    var refs = React.useRef([]);

    var currentElevation = 0;
    var summaryAvailData = null;
    var summaryCompData = null;

    console.log(JSON.stringify(initialViewState));
    const [params, setParams] = useState({
      parcelAPN: null,
      parcelInfo: null,
      open: false,

      parcelResults: null,
    });

    var myViewport;
    var viewStateCookie;

    // Scroll the selected card into view when targetId or searchResults change
    useEffect(() => {
      if (selectedLocation) {
        console.log("scrolling");
        let result = null;
        let index = 0;
        for (const searchResult of searchResults) {
          if (searchResult.id === selectedLocation.id) {
            result = { index: index };
          } else {
            index++;
          }
        }
        if (result) {
          scrollToResult(result);
        }
      } else {
        console.log("no selected location");
      }
    }, [searchResults]);

    const scrollToResult = (result) => {
      if (refs && refs.current[result.index]) {
        refs.current[result.index].scrollIntoView({
          // behavior: 'smooth',
          block: "nearest",
          inline: "start",
        });
      }
    };

    useImperativeHandle(ref, () => ({
      async mapReload() {
        console.log("map reload 1");
        await mapReload().catch((error) => {
          console.log(error);
        });
      },
      removeProperty(property) {
        removeProperty(property);
      },
      redoIcons() {
        var billBoardLayer = returnBillBoardLayer({
          getElevationFromZoom,
          currentZoom,
          currentElevation,
          searchResults,
          coords,
          mobileAndTabletCheck,

          getCookie,
          selectedLocation,
          filters,
          createCookie,
          setSelectedLocation,
          scrollToResult,
          overlay,
          recalcLayers,
          onLayerClick,
        });

        overlay.setProps({ layers: [billBoardLayer] });
        overlay.setProps({ layers: recalcLayers() });
      },

      setSearchedLocations(coordinates) {
        // setSearchedLocations(coordinates)
        searchedLocations = coordinates;
        overlay.setProps({ layers: recalcLayers() });
      },
      async refreshMapProperties() {
        // var myViewport = getCurrentViewState()
        console.log("refresh viewportElevation");
        if (viewportElevation.elevation === -1) {
          viewportElevation = await getViewportElevation(
            mapRef,
            searchResults && searchResults.length > 0 && searchResults[0]
          );
        }

        var groundElevation = viewportElevation;
        console.log("refresn in imperitive handle: " + new Date().getSeconds());

        refreshMapProperties(
          searchResults,
          elevationFactorState,
          groundElevation,
          viewportElevation,
          filters
        );
        overlay.setProps({ layers: recalcLayers() });
      },
    }));

    var mapDataElevation =
      mapData &&
      mapData.elevation &&
      mapData.elevation.data &&
      mapData.elevation.data.elevation;
    const removeCustomLayer = (map) => {
      if (map.getLayer("custom-geojson-layer")) {
        map.removeLayer("custom-geojson-layer"); // Also remove the source if it's unique to this layer
        if (map.getSource("custom-geojson-source")) {
          map.removeSource("custom-geojson-source");
        }
      }
      setIsLayerAdded(false);
    };

    let currentZoom;

    // Effect to handle layer visibility based on zoom level
    useEffect(() => {
      var map = mapRef.current && mapRef.current.getMap();
      if (!map) return;

      const handleZoom = () => {
        if (!map) {
          map = mapRef?.current && mapRef.current.getMap();
          if (!map) {
            return;
          }
        }

        currentZoom = map.getZoom();

        if (currentZoom < 6) {
          // Check if the layer already exists before adding
          // removeCustomLayer(map)
          generateGlobeSummaryLayer(map, clusterData);
          setIsLayerAdded(true);
        } else if (currentZoom >= 6 && isLayerAdded) {
          removeCustomLayer(map);
        }
      };

      // Ensure the custom layer is added and initially set to the correct visibility

      handleZoom();

      // Listen to zoom events to toggle layer visibility
      map.on("zoom", handleZoom);

      // Clean up
      return () => {
        map.off("zoom", handleZoom);
      };
    }, [clusterData]); // Re-run effect if viewport changes

    function getCurrentViewState(mapRefIn) {
      // var theMapRef = mapRefIn || mapRef
      var viewState = null;
      if (mapRef && mapRef.current && mapRef.current.getZoom) {
        viewState = {};

        viewState.zoom = mapRef.current.getZoom();
        viewState.pitch = mapRef.current.getPitch();
        viewState.bearing = mapRef.current.getBearing();
        viewState.center = mapRef.current.getCenter();
        viewState.maxZoom = mapRef.current.getMaxZoom();
        viewState.minZoom = mapRef.current.getMinZoom();
        viewState.latitude = viewState.center.lat;
        viewState.longitude = viewState.center.lng;
      } else if (mapRef && mapRef.current && mapRef.current.deck) {
        viewState = mapRef.current.deck.viewState["default-view"];
      }
      return viewState;
    }
    var currentState = getCurrentViewState();

    const onLayerClick = (eventClick, info) => {
      // info.preventDefault();
      // info.stopImmediatePropagation();

      // eventClick.stopPropigation()

      onMarkerClick(eventClick.object, setSearchResults);
    };

    function onMapLoadMapBox(event) {
      onMapLoad();
    }

    async function onMapLoad() {
      fabRefreshRef.current.setShowWait(true);
      console.log("map loading!");

      if (geometry) {
        console.log("geometry call in map load");

        if (!geometry.location.elevation) {
          var elevationData = await axios.get(
            process.env.REACT_APP_DATABASE +
              "api/getElevationLocal?latitude=" +
              (geometry.location.lat + "&longitude=" + geometry.location.lng)
          );

          if (elevationData && elevationData.data.elevation !== "not found") {
            geometry.location.elevation = elevationData.data.elevation;
          } else {
            geometry.location.elevation = 5;
          }
        }

        currentElevation = geometry.location.elevation;
        searchedLocations = [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [
                geometry.location.lng,
                geometry.location.lat,
                geometry.location.elevation - 10,
              ],
            },
          },
        ];
        console.log("refresh started from onMapLoad");

        refreshMapProperties(
          searchResults,
          elevationFactorState,
          viewportElevation,
          viewportElevation,
          filters
        );

        mapRef.current.addControl(overlay);

        await mapReload();
        overlay.setProps({ layers: recalcLayers() });
        fabRefreshRef &&
          fabRefreshRef.current &&
          fabRefreshRef.current.setShowWait &&
          fabRefreshRef.current.setShowWait(false);
        return true;
      } else {
        console.log("mapload else getviewportElevation");
        if (viewportElevation.elevation === -1) {
          viewportElevation = await getViewportElevation(
            mapRef,
            searchResults && searchResults.length > 0 && searchResults[0]
          );
        }

        if (viewportElevation && viewportElevation.elevation) {
          currentElevation = viewportElevation.elevation;
        }

        refreshMapProperties(
          searchResults,
          elevationFactorState,
          viewportElevation,
          viewportElevation,
          filters
        );

        await mapReload();
        getCities(
          myViewport,
          theViewStateOuter,
          elevationFactorState,
          viewportElevation
        );
        overlay.setProps({ layers: recalcLayers() });
        mapRef.current.addControl(overlay);
        fabRefreshRef.current.setShowWait(false);

        return true;
      }
    }

    const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX;

    const [credits, setCredits] = useState("");

    function recursivePointEdit(coordinateArrays, zValue) {
      if (
        (coordinateArrays.length === 3 || coordinateArrays.length === 2) &&
        !coordinateArrays[0].length
      ) {
        if (coordinateArrays.length === 3) {
          coordinateArrays[2] = zValue;
        } else {
          coordinateArrays.push(zValue);
        }
        return coordinateArrays;
      } else {
        coordinateArrays.map((eachArray, index) => {
          coordinateArrays[index] = recursivePointEdit(eachArray, zValue);
        });
        return coordinateArrays;
      }
    }

    if (mapData && mapData.features && parseInt(mapDataElevation) > 0) {
      // this is parcel data
      mapData.features.map((result, index) => {
        mapData.features[index].geometry.coordinates = recursivePointEdit(
          mapData.features[index].geometry.coordinates,
          mapDataElevation
        );
      });
    }

    // var sessionKey = await axios.post("https://tile.googleapis.com/tile/v1/createSession?maptype=satellite&key="+ process.env.REACT_APP_GoogleMapsAPIKey )

    function getMyViewport(newView) {
      var viewState = {};
      var bounds = null;

      if (newView) {
        viewState = newView;
      } else if (mapRef && mapRef.current && mapRef.current.getZoom) {
        bounds = mapRef.current.getBounds();

        var myViewport = bounds;

        return myViewport;
      } else if (mapRef && mapRef.current && mapRef.current.deck) {
        viewState = mapRef.current.deck.viewState["default-view"];
      } else {
        viewState = initialViewState;
      }

      if (!viewState || !viewState.zoom) {
        // alert(" check getMyViewPort" + JSON.stringify(viewState))
        return null;
      }
      const viewport =
        viewState && viewState.zoom
          ? new WebMercatorViewport({
              width: viewState.width, //mapRef.current.deck.width*.0625 ,
              height: viewState.height, //mapRef.current.deck.height*.0625 ,
              longitude: viewState.longitude,
              latitude: viewState.latitude,
              zoom: viewState.zoom,
              pitch: viewState.pitch,
              bearing: viewState.bearing,
            })
          : new WebMercatorViewport({});

      const nw = viewport.unproject([0, 0]);

      const se = viewport.unproject([viewport.width, viewport.height]);

      var myBounds = viewport.getBounds();

      var myViewport = {
        _ne: {
          lat: myBounds?.[3], //nw[1],
          // lat: nw[1],
          lng: myBounds?.[2], //se[0],
          // lng: se[0],
        },
        _sw: {
          lat: myBounds?.[1], //,se[1],
          // lat: se[1],
          lng: myBounds?.[0], //nw[0]
          //lng: nw[0]
        },
      };
      return myViewport;
    }

    var theViewStateOuter = initialViewState;
    if (mapRef && mapRef.current && mapRef.current.getZoom) {
      theViewStateOuter.zoom = mapRef.current.getZoom();
      theViewStateOuter.pitch = mapRef.current.getPitch();
      theViewStateOuter.bearing = mapRef.current.getBearing();
      theViewStateOuter.center = mapRef.current.getCenter();
      theViewStateOuter.maxZoom = mapRef.current.getMaxZoom();
      theViewStateOuter.minZoom = mapRef.current.getMinZoom();
      theViewStateOuter.latitude = theViewStateOuter.center.lat;
      theViewStateOuter.longitude = theViewStateOuter.center.lng;
    } else if (
      mapRef.current &&
      mapRef.current.deck.viewState["default-view"]
    ) {
      theViewStateOuter = mapRef.current.deck.viewState["default-view"];
    }

    function aboveOrBelow() {
      var aboveGroundSummarizeDistance = 10000;

      var theViewState = getCurrentViewState();
      console.log(initialViewState);
      console.log("ttt");
      // var theViewPort = getMyViewport()
      var camElevation = getElevationFromZoom(
        (theViewState && theViewState.zoom) || 15
      );
      var theVarElevation =
        currentElevation > 0
          ? currentElevation
          : searchResults && searchResults.length > 0
          ? searchResults[0].Elevation
          : 5;
      if (!theVarElevation) {
        theVarElevation = 10;
      }
      var distanceFromGround = camElevation - theVarElevation;
      if (distanceFromGround > aboveGroundSummarizeDistance) {
        return "above";
      } else {
        return "below";
      }
    }

    const mapReload = async () => {
      console.log("map reload called " + new Date().getSeconds());

      return new Promise(async function (resolve, reject) {
        fabRefreshRef.current.setShowWait(true);

        var newClusterData;
        var theViewPort = getMyViewport();
        theViewState = getCurrentViewState();
        var camElevation = getElevationFromZoom(
          (theViewState && theViewState.zoom) || 15
        );

        if (!viewportElevation || viewportElevation.elevation === -1) {
          viewportElevation = await getViewportElevation(
            mapRef,
            searchResults && searchResults.length > 0 && searchResults[0]
          );
        }
        var distanceFromGround = camElevation - viewportElevation.elevation;

        if (!theViewState) {
          theViewState = {};
        }
        theViewState.height = distanceFromGround;
        console.log(theViewState);

        if (
          aboveOrBelow() === "below" ||
          (!reloadOnMove && aboveOrBelow === "above")
        ) {
          // we look for cities and streets regardless so long as we are below the threashold

          var citiesSet = await getCities(
            theViewPort,
            theViewState,
            elevationFactorState,
            viewportElevation
          );
          // this will set the cities state
          var streetsSet = await getStreets(
            theViewPort,
            theViewState,
            viewportElevation,
            elevationFactorState,
            viewportElevation
          );
        }

        var maxPropsForDetail = mobileAndTabletCheck() ? 50 : 50;
        filters.maxPropsForDetail = maxPropsForDetail;
        filters.contactId = contactId;
        filters.contact_id = contactId;

        if (typeof filters.availPropertyType === "string") {
          filters.availPropertyType = filters.availPropertyType.split(",");
        }
        if (typeof filters.buildingPropertyType === "string") {
          filters.buildingPropertyType =
            filters.buildingPropertyType.split(",");
        }

        if (reloadOnMove && contactId) {
          // reload has to be set to get a refresh
          console.log("loading data " + new Date().toLocaleString());
          const newClusterDataJson = await postAxios(
            process.env.REACT_APP_DATABASE + "api/clusterAvails",
            {
              filters: filters,
              viewPort: theViewPort,
              viewState: theViewState,
              contactId: contactId,
            },
            newStateController
          ).catch((error) => {
            console.log(error);
          });

          newClusterData = newClusterDataJson?.data?.geojson;
          summaryAvailData = newClusterDataJson?.data?.availablesGeoJson;
          summaryCompData = newClusterDataJson?.data?.compsGeoJson;
          const cubicBuildingData = newClusterDataJson?.data;

          if (
            newClusterData &&
            (newClusterData.totalClusterBuildings ||
              newClusterData.totalClusterBuildingsComps) &&
            !(
              showParcels
              // && aboveOrBelow() === 'below'
            )
          ) {
            // we are high up

            clusterVarData = newClusterData;

            setClusterData(clusterVarData); // we keep cluster and erase old results

            if (searchResults && searchResults.length > 0) {
              // clear search results
              setSearchResults([]);
            }
            searchResults = [];
            coords = [];

            // refreshMapProperties(searchResults, elevationFactorState, viewportElevation, viewportElevation, filters) // this gets the data for the layers
            overlay.setProps({ layers: recalcLayers() });
            fabRefreshRef?.current?.setShowWait(false);
            console.log(theViewState);
            resolve(true);
            return true;
          } else {
            // we have detail and the data will be searchResults in stead of - we are close in

            clusterVarData = [];

            setClusterData(clusterVarData);

            if (!mobileAndTabletCheck()) {
              if (theViewState.zoom >= 15 && showParcels) {
                // this sets parcels  we get parcels not on a phone and close in

                //test - console.log("getGeo")
                getGeo(setMapData, theViewPort);
              }
            }

            var cubicBuildingResultsData = cubicBuildingData;

            var myBoundsArcGIS =
              theViewPort._sw.lng +
              "," +
              theViewPort._sw.lat +
              "," +
              theViewPort._ne.lng +
              "," +
              theViewPort._ne.lat;

            var osmEuropeBuildingsData = {
              data: {
                features: [],
              },
            };
            var osmNABuildingsData = {
              data: {
                features: [],
              },
            };

            if (
              filters?.allCheck &&
              cubicBuildingResultsData?.length < maxPropsForDetail
            ) {
              // if both are unchecked  there are less than 50 buildings  we check osm database

              var qsBuildings =
                "https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_NA_Buildings/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry=" +
                myBoundsArcGIS +
                "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson";

              osmNABuildingsData = await getAxios(
                qsBuildings,
                newNAMaster
              ).catch((err) => {
                console.log("osm request aborted");
                //reject(err)
                console.log(err);
                //setShowWait(false)
                return false;
              });

              if (
                !osmNABuildingsData ||
                osmNABuildingsData.data.features.length === 0
              ) {
                var qsBuildingsEurope =
                  "https://services-eu1.arcgis.com/zci5bUiJ8olAal7N/arcgis/rest/services/OSM_Buildings_EU/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry=" +
                  myBoundsArcGIS +
                  "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson";
                osmEuropeBuildingsData = await axios
                  .get(qsBuildingsEurope)
                  .catch((err) => {
                    console.log("get request aborted");
                  });
                if (!osmEuropeBuildings) {
                  qsBuildingsEurope =
                    "https://services6.arcgis.com/Do88DoK2xjTUCXd1/arcgis/rest/services/OSM_CA_Buildings/FeatureServer/0/query?where=Shape__Area>1000&outFields=Shape__Area,height,levels,addr_street,addr_housenumber,type,name,official_name,addr_city,addr_state,addr_postcode,addr_district,start_date,building_structure,power,description&geometry=" +
                    myBoundsArcGIS +
                    "&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson";
                  osmEuropeBuildingsData = await axios
                    .get(qsBuildingsEurope)
                    .catch((err) => {
                      console.log("get request aborted");
                    });
                }
              }
            } else {
              osmNABuildingsData.data = [];
            }

            var geojsonArea = require("@mapbox/geojson-area");
            var minArea = 500;

            var cubicBuildingResults = cubicBuildingResultsData
              ? cubicBuildingResultsData
              : []; // we get cubic results data
            var allViewportResults = cubicBuildingResults;
            var osmNABuildings = osmNABuildingsData.data;
            // var osmNABuildings = pointResult && pointResult.data ? pointResult.data : osmNABuildingsData.data
            var osmEuropeBuildings = osmEuropeBuildingsData
              ? osmEuropeBuildingsData.data
              : null;
            // var missingBuildingHeight = []
            // var missingElevation = []

            if (
              osmNABuildings &&
              osmNABuildings.features &&
              osmNABuildings.features.length > 75
            ) {
              // if thre are a lot of osm buildings take teh 75 tallest
              osmNABuildings.features = osmNABuildings.features.sort((a, b) => {
                //return b.properties.Shape__Area - a.properties.Shape__Area
                if (!b.properties.height) {
                  return -1;
                }
                if (!a.properties.height) {
                  return 1;
                }

                return b.properties.height - a.properties.height;
              });

              osmNABuildings.features = osmNABuildings.features.slice(0, 75);
            }
            //test - console.log(qsBuildingsEurope)

            if (
              osmEuropeBuildings &&
              osmEuropeBuildings.features &&
              osmEuropeBuildings.features.length > 75
            ) {
              // if thre are a lot of osm buildings take teh 75 tallest
              osmEuropeBuildings.features = osmEuropeBuildings.features.sort(
                (a, b) => {
                  //return b.properties.Shape__Area - a.properties.Shape__Area
                  if (!b.properties.height) {
                    return -1;
                  }
                  if (!a.properties.height) {
                    return 1;
                  }

                  return b.properties.height - a.properties.height;
                }
              );

              osmEuropeBuildings.features = osmEuropeBuildings.features.slice(
                0,
                75
              );
            }

            if (!osmNABuildings && osmEuropeBuildings) {
              osmNABuildings = osmEuropeBuildings;
            }

            if (
              osmNABuildings &&
              osmNABuildings.features &&
              osmNABuildings.features.length > 0
            ) {
              // we have North America Features to check

              var cubicOverlappers = await cubicOverlap(
                allViewportResults,
                osmNABuildings
              );

              var newSearchResults = await overLap(
                osmNABuildings,
                allViewportResults,
                mapRef
              );
              // .then((newSearchResults) => {

              newSearchResults = newSearchResults.concat(cubicBuildingResults);
              cubicOverlappers.buildingsToDelete.map((deadBuilding) => {
                newSearchResults = newSearchResults.filter((eachResult) => {
                  return newSearchResults.id != deadBuilding.id;
                });
              });

              //test - console.log("setting nA search results")
              // console.log("filtering at 1036")
              newSearchResults = newSearchResults.sort(
                sortByProperty("Latitude")
              ); // sort the rest north to South.  This is only for OSMs

              newSearchResults = newSearchResults.filter((result, index) => {
                if (result.avails && result.avails.length > 0) {
                  return true;
                }
                if (
                  !result.Footprint &&
                  !result.Size &&
                  !result.PropertyName &&
                  !result.CampusName
                ) {
                  return false;
                } else {
                  if (newSearchResults.length > 50) {
                    if (
                      (result.FootPrint && result.Footprint > 10000) ||
                      (result.Size && result.Size > 15000)
                    ) {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }
              });

              setSearchResults(newSearchResults);

              searchResults = newSearchResults;

              // refreshMapProperties(newSearchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation)
              if (mobileAndTabletCheck() && theViewState) {
                if (theViewState.zoom >= 15 && showParcels) {
                  //test - console.log("getGeo")
                  getGeo(setMapData, theViewPort);
                }
              }
              var theViewState = getCurrentViewState();

              var viewStateCookie = {
                ViewState: theViewState,
                searchResults: newSearchResults,
                filters: filters,
                URL: window.location.href,
              };

              var postResult = axios
                .post(process.env.REACT_APP_DATABASE + "api/createViewStates", {
                  userId: contactId,
                  ViewState: viewStateCookie,
                })
                .catch((error) => {
                  console.log(error);
                });

              refreshMapProperties(
                searchResults,
                elevationFactorState,
                viewportElevation,
                viewportElevation,
                filters
              ); // this gets the data for the layers
              overlay.setProps({ layers: recalcLayers() });
              fabRefreshRef.current.setShowWait(false);
              console.log(theViewState);
              resolve(true);
              return true;
            } else if (
              osmEuropeBuildings &&
              osmEuropeBuildings.features &&
              osmEuropeBuildings.features.length > 0
            ) {
              // europe features to check
              overLap(osmEuropeBuildings, allViewportResults, mapRef).then(
                (newSearchResults) => {
                  newSearchResults = newSearchResults
                    .concat(cubicBuildingResults)
                    .sort(sortByProperty("Latitude"));

                  console.log("filter at 1064");
                  alert("removed these filters temp");

                  setSearchResults(newSearchResults);
                  // refreshMapProperties(newSearchResults, setCoords, setCoordsCircles, setCoordsLines, elevationFactorState, groundElevation, viewportElevation)
                  if (mobileAndTabletCheck()) {
                    if (
                      theViewState &&
                      theViewState.zoom >= 15 &&
                      showParcels
                    ) {
                      //test - console.log("getGeo")
                      getGeo(setMapData, theViewPort);
                    }
                  }
                }
              );
            } else {
              // most common, we are not looking for empty buildings, just the ones available

              setSearchResults(
                cubicBuildingResults && cubicBuildingResults.length > 0
                  ? cubicBuildingResults.sort(sortByProperty("Latitude"))
                  : []
              );
              searchResults =
                cubicBuildingResults && cubicBuildingResults.length > 0
                  ? cubicBuildingResults
                  : [];

              var theViewState = getCurrentViewState();

              var viewStateCookie = {
                ViewState: theViewState,
                searchResults: searchResults,
                filters: filters,
                URL: window.location.href,
              };

              var postResult = axios
                .post(process.env.REACT_APP_DATABASE + "api/createViewStates", {
                  userId: contactId,
                  ViewState: viewStateCookie,
                })
                .catch((error) => {
                  console.log(error);
                });

              refreshMapProperties(
                searchResults,
                elevationFactorState,
                viewportElevation,
                viewportElevation,
                filters
              ); // this gets the data for the layers
              overlay.setProps({ layers: recalcLayers() });
              fabRefreshRef &&
                fabRefreshRef.current &&
                fabRefreshRef.current.setShowWait &&
                fabRefreshRef.current.setShowWait(false);
              console.log(theViewState);
              resolve(true);
              return true;
            }

            alert("not supposed to get here");
          }
        } else {
          console.log(theViewState);
          resolve(true);
          return 1;
        }
      });
    };

    if (mapData && !showParcels) {
      //test - console.log("nulling map data")
      setMapData(null);
    }

    useEffect(() => {
      if (initialViewState.transitionDuration > 0) {
        if (mapRef && mapRef.current && mapRef.current.getCenter) {
          // alert("transition longitude: " + initialViewState.longitude + " latitude: " + initialViewState.latitude)
          var rawCookie = axios
            .get(
              process.env.REACT_APP_DATABASE +
                "api/getViewStates?userId=" +
                contactId
            )
            .then(async (rawCookie) => {
              if (!rawCookie || !rawCookie.data) {
                viewStateCookie = [];
              }
              {
                viewStateCookie = rawCookie.data;
              }

              if (
                viewStateCookie &&
                // (viewStateCookie.viewStates.length > 0) &&

                window.location.href ===
                  viewStateCookie.viewStates[0].ViewState.URL
              ) {
                myViewport = viewStateCookie.viewStates[0].ViewState.ViewState;

                if (myViewport && mapRef && mapRef.current) {
                  myViewport.transitionDuration = 0;

                  setInitialViewState(myViewport);

                  mapRef.current.flyTo({
                    center: [myViewport.longitude, myViewport.latitude],
                    zoom: myViewport.zoom,
                    duration: 2000,
                  });
                  refreshMapProperties(
                    searchResults,
                    elevationFactorState,
                    viewportElevation,
                    viewportElevation,
                    filters
                  );
                  overlay.setProps({ layers: recalcLayers() });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
          //test - console.log("we have view states")
        }
      }
    }, [initialViewState]);

    if (popupInfo && !drawerOpen) {
      setDrawerOpen(true);
    }

    // We'll shift the entire tileset up by 20m above the local ground.
    const OFFSET_METERS = 30;
    const localUpMatrix = getLocalUpTransform(
      initialViewState.latitude,
      initialViewState.longitude,
      OFFSET_METERS
    );
    function returnGoogletileLayer() {
      return new Tile3DLayer({
        id: "google-3d-tiles",

        data: TILESET_URL,
        // visible: visibleState,
        onTilesetLoad: (tileset3d) => {
          // 1) Grab the tileset's original transform (ECEF-based)
          const originalMatrix = tileset3d.modelMatrix; // a Matrix4
          // 2) Combine it with the local up offset
          const combinedMatrix = new Matrix4(originalMatrix).multiplyRight(
            localUpMatrix
          );
          // 3) Directly assign the new matrix (instead of setModelMatrix)
          tileset3d.modelMatrix = combinedMatrix;
          tileset3d.options.onTraversalComplete = (selectedTiles) => {
            const uniqueCredits = new Set();

            selectedTiles.forEach((tile, index) => {
              const { copyright } = tile.content.gltf.asset;
              copyright.split(";").forEach(uniqueCredits.add, uniqueCredits);
            });
            setCredits([...uniqueCredits].join("; "));

            return selectedTiles;
          };

          console.log(
            "Tileset loaded. Applied local up offset:",
            combinedMatrix
          );
        },

        pointSize: 10,
        operation: "terrain+draw",
      });
    }

    async function refreshMapPropertiesInMain() {
      if (!viewportElevation || viewportElevation.elevation === -1) {
        var groundElevation = await getViewportElevation(
          mapRef,
          theViewStateOuter
        );
      }
      viewportElevation = groundElevation;
      console.log("calling refresh from 2519: " + new Date().getSeconds());
      refreshMapProperties(
        searchResults,
        elevationFactorState,
        groundElevation,
        viewportElevation,
        filters
      );
    }

    const recalcLayers = () => {
      // if you are here for the first time, you might have a geometry that had an empty set of properties or an incomplete viewstate
      // just make sure you get to a map layer.
      fabRefreshRef &&
        fabRefreshRef.current &&
        fabRefreshRef.current.setShowWait &&
        fabRefreshRef.current.setShowWait(true);
      console.log("setting layers" + new Date().getSeconds());

      var camElevation = getElevationFromZoom(
        (currentState && currentState.zoom) || 15
      );
      var theVarElevation =
        currentElevation > 0
          ? currentElevation
          : searchResults && searchResults.length > 0
          ? searchResults[0].Elevation
          : 5;
      if (!theVarElevation) {
        theVarElevation = 10;
      }
      var distanceFromGround = camElevation - theVarElevation;

      let buildingBillboards;
      let availSummaryLayer;
      let compSummaryLayer;

      if (searchResults.length > 0) {
        buildingBillboards = returnBillBoardLayer({
          getElevationFromZoom,
          currentZoom,
          currentElevation,
          searchResults,
          coords,
          mobileAndTabletCheck,

          getCookie,
          selectedLocation,
          filters,
          createCookie,
          setSelectedLocation,
          scrollToResult,
          overlay,
          recalcLayers,
          onLayerClick,
        });
      }

      availSummaryLayer = createAvailSummaryLayer({
        summaryAvailData,
        mobileAndTabletCheck,

        mapRef,
        sizeThresholds,
        camElevation,
        distanceFromGround,
      });
      compSummaryLayer = createCompSummaryLayer({
        summaryCompData,
        mobileAndTabletCheck,

        mapRef,
        sizeThresholds,
        camElevation,
        distanceFromGround,
      });

      const buildingPostLayer = generateBuildingPostLayer(
        coordsLines,
        elevationFactorState,
        mobileAndTabletCheck
      );

      const parcelLayer = new GeoJsonLayer({
        // this is the parcels
        id: "geojson-layer",
        data: mapData,
        extensions: [new TerrainExtension()],
        stroked: true,
        extruded: true,
        wireframe: true,
        getElevation: 1,

        filled: true,
        pickable: true,
        getFillColor: [100, 100, 180, 50],

        getLineColor: [255, 20, 0, 200],
        getLineWidth: 4,
        autoHighlight: true,
        highlightColor: [0, 20, 255, 100],
        terrainDrawMode: "offset",

        onClick: (info, event) => {
          // setShowWait(true)

          var hoverInfo = {
            parcelProperties: info.object.properties,
            center: center(
              info.object.geometry.type === "MultiPolygon"
                ? info.object.geometry.coordinates[0]
                : info.object.geometry.coordinates
            ),
            latitude: info.coordinate[1],
            longitude: info.coordinate[0],
            geometry: info.object.geometry,
          };

          prepareTestAlert(hoverInfo, true, params, setParams, setShowWait);
        },
      });
      console.log("gg");
      console.log(JSON.stringify(searchedLocations));
      const flagLayer = new ScenegraphLayer({
        // you are here change icon
        id: "geojson-Icon",
        data: searchedLocations,
        sizeMinPixels: 10,
        sizeMaxPixels: 30,
        // scenegraph: "https://images.cubicsearch.com/icons/whiteflag.glb",
        scenegraph: "/models/bluemarker.glb",
        extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()],
        terrainDrawMode: mobileAndTabletCheck() ? null : "offset",
        getPosition: (d) => {
          return [
            d.geometry.coordinates[0],
            d.geometry.coordinates[1],
            d.geometry.coordinates[2] - 20,
          ];
        },
        getOrientation: (d) => [0, 0, 90],
        _animations: {
          "*": { speed: 1 },
        },
        sizeScale: 1,
        _lighting: "pbr",

        pickable: true,

        onClick: (info) => {
          // var text = mapRef.current.pickObject({ x: info.pixel[0], y: info.pixel[1], id: ['google-3d-tiles'], unproject3D : true})
          // var group = mapRef.current.pickMultipleObjects({ x: info.pixel[0], y: info.pixel[1], radius: 400, id: ['geojson-layerText'], unproject3D: true })

          alert("This is the location you just searched!");
        },
      });

      console.log("hh");

      const streetPostLayer = new ColumnLayer({
        id: "line-layer",
        data: streetsLinesOnMap,
        diskResolution: 9,
        radius: 1,
        extruded: true,
        extensions: mobileAndTabletCheck() ? [] : [new TerrainExtension()],
        terrainDrawMode: mobileAndTabletCheck() ? null : "offset",
        pickable: false,
        elevationScale: 0.99,
        getPosition: (d) => d.geometry.coordinates,
        getFillColor: (d) => [255, 0, 0],
        getLineColor: [0, 0, 0],
        getElevation: (d) => (mobileAndTabletCheck() ? d.elevation : 50), //
      });
      console.log("ii");

      const streetBillboards = new GeoJsonLayer({
        id: "geojson-streets",
        data: streetsOnMap,
        extensions: !mobileAndTabletCheck() ? [new TerrainExtension()] : [],
        terrainDrawMode: "offset",
        // stroked: true,
        pointType: "text",
        getLineColor: [255, 20, 0],
        getText: (d) => {
          return d.name;
        },
        // getTextAngle: d =>
        //     // mapRef.current.deck.viewState["default-view"].bearing +
        //     d.angle,
        textBackground: true,
        textFontWeight: 900,
        getTextBackgroundColor: (d) => {
          if (d.properties.highway === "motorway") {
            return [100, 155, 100];
          } else {
            return [150, 150, 150];
          }
        },
        textBillboard: true,
        textBackgroundPadding: [4, 4],
        textFontFamily: "Gill Sans, sans-serif",
        getTextColor: [255, 255, 255],

        // getAlignmentBaseline: () => 'bottom',

        getTextAnchor: (d) => "middle",
        // getAligmentBaseline: d => "top",
        lineWidthScale: 10,
        lineWidthMinPixels: 2,
        getFillColor: [160, 160, 180],
        getTextSize: 12,
      });
      console.log("jj");
      const cityBillboards = new GeoJsonLayer({
        id: "geojson-cities",
        data: citiesOnMap,
        extensions: !mobileAndTabletCheck() ? [new TerrainExtension()] : [],
        terrainDrawMode: "offset",
        // stroked: true,
        pointType: "text",
        getLineColor: [255, 20, 0],
        getText: (d) => {
          return d.name;
        },

        textBackground: true,
        textFontWeight: 900,
        getTextBackgroundColor: (d) => {
          if (d.properties.highway === "city") {
            return [100, 155, 100, 255];
          } else {
            return [0, 0, 0];
          }
        },
        textBackgroundPadding: [4, 4],
        textFontFamily: "Gill Sans, sans-serif",
        getTextColor: [255, 255, 255],
        // getAlignmentBaseline: () => 'bottom',

        getTextAnchor: (d) => "middle",
        // getAligmentBaseline: d => "top",
        lineWidthScale: 10,
        lineWidthMinPixels: 2,
        getFillColor: [160, 160, 180, 200],
        getTextSize: 20,
      });

      var layersDeckGL = [];

      const buildingCirclesLayer = generateBuildingCirclesLayer(
        coordsCircles,
        popupInfo
      );

      // console.log("You have" + clusterVarData && clusterVarData.features && clusterVarData.features.length + "results and you are " && aboveOrBelow())

      if (
        coords &&
        coords.length > 0 // we may have reset the search so this may need to go.  We try it.
      ) {
        layersDeckGL.push(buildingPostLayer);
        if (!mobileAndTabletCheck()) {
          layersDeckGL.push(buildingCirclesLayer);
        }
        layersDeckGL.push(buildingBillboards);
      } else {
        if (currentState?.zoom > 6) {
          layersDeckGL.push(availSummaryLayer);
          layersDeckGL.push(compSummaryLayer);
        }
      }

      if (
        parcelLayer &&
        showParcels &&
        currentState &&
        aboveOrBelow() === "below"
      ) {
        layersDeckGL.push(parcelLayer);
      }

      if (aboveOrBelow() === "below") {
        const google3dLayer = returnGoogletileLayer();
        layersDeckGL.push(
          cityBillboards,
          streetBillboards,
          streetPostLayer,
          google3dLayer
        );
      }

      if (
        flagLayer &&
        searchedLocations &&
        searchedLocations.length === 1 &&
        searchedLocations[0].geometry.coordinates.length === 3 &&
        !isNaN(searchedLocations[0].geometry.coordinates[0]) &&
        !isNaN(searchedLocations[0].geometry.coordinates[1]) &&
        !isNaN(searchedLocations[0].geometry.coordinates[2])
        // && (flagLayer.lifecycle !== "Awaiting state")
      ) {
        // alert(JSON.stringify(searchedLocations[0].geometry.coordinates))
        console.log("scenegraph pushed");
        if (currentState?.zoom > 6) {
          layersDeckGL.push(flagLayer);
        }
      }

      fabRefreshRef &&
        fabRefreshRef.current &&
        fabRefreshRef.current.setShowWait &&
        fabRefreshRef.current.setShowWait(false);
      return layersDeckGL;
    };

    const handleMoveStart = debounce(() => {
      console.log("restarted");
      console.log(" mapbox onZoomStart");

      var abortAnswer = controller.abort();
      controllerEurope.abort();
      controllerNA.abort();
      controllerAll.abort();
      controllerCities.abort();
      newAllController.abort();
    }, 100);

    const handleOnMoveEnd = debounce(
      async (ev) => {
        // Do stuff with the event!

        if (reloadOnMove) {
          await mapReload();
        } else {
          // var googleLayer = returnGoogletileLayer()
          //
          var theViewport = getMyViewport();
          var viewportElevation = await getViewportElevation(mapRef);

          if (aboveOrBelow() === "below") {
            await getCities(
              theViewport,
              theViewStateOuter,
              elevationFactorState,
              viewportElevation
            );
            var streetsSet = await getStreets(
              theViewport,
              theViewStateOuter,
              viewportElevation,
              elevationFactorState,
              viewportElevation
            );
            refreshMapProperties(
              searchResults,
              elevationFactorState,
              viewportElevation,
              viewportElevation,
              filters
            );
          }

          overlay.setProps({ layers: recalcLayers() });
        }
      },
      mobileAndTabletCheck() ? 750 : 1000
    );

    function removeProperty(property) {
      setSelectedLocation(property);

      var splicedArray = removeById(searchResults, property.id);
      refreshMapProperties(
        splicedArray,
        elevationFactorState,
        viewportElevation,
        viewportElevation,
        filters
      );

      overlay.setProps({ layers: recalcLayers() });

      setTimeout(() => {
        if (splicedArray.length === 0) {
          mapReload();
        }
        setSearchResults(splicedArray);
      }, 500);
    }

    return (
      <>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%",
       
          }}
        >
          <DeckSplitPanel
            filterInputs={filterInputs}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            refs={refs} // Replace with your actual refs
            confirmIn={confirmIn}
            contactId={contactId}
            clusterData={clusterData}
            removeProperty={removeProperty}
            surveyId={surveyId}
            setShowSurveys={setShowSurveys}
            setSaveSurveyOpen={setSaveSurveyOpen}
            initialViewState={initialViewState}
            overlay={overlay}
            recalcLayers={recalcLayers}
            setReloadOnMove={setReloadOnMove} // Replace with your actual function
            mapRef={mapRef}
            getZoomFromElevation={getZoomFromElevation}
            viewportElevation={viewportElevation}
            onMapLoadMapBox={onMapLoadMapBox}
            handleMoveStart={handleMoveStart}
            handleOnMoveEnd={handleOnMoveEnd}
            aboveOrBelow={aboveOrBelow}
            credits={credits}
            MAPBOX_TOKEN={MAPBOX_TOKEN}
          />
          <TestAlert params={params} setParams={setParams} />

          {mobileAndTabletCheck() ? (
            <SwipeableTemporaryDrawer
              open={drawerOpen}
              mapButton={mapButton}
              setPopupInfo={setPopupInfo}
              setOpen={setDrawerOpen}
              removeProperty={removeProperty}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              cardProps={popupInfo ? popupInfo.object : null}
              refreshMapPropertiesInMain={refreshMapPropertiesInMain}
              mapRef={mapRef}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              setShowSurveys={setShowSurveys}
              surveyId={surveyId}
              setSaveSurveyOpen={setSaveSurveyOpen}
            />
          ) : (
            <></>
          )}
        </Box>
      </>
    );
  }
);

export default Decky;
//

// //test - console.log(unique(array, ['class', 'fare']));

function unique(arr, keyProps) {
  const kvArray = arr.map((entry) => {
    const key = keyProps.map((k) => entry[k].toString()).join("|");
    return [key, entry];
  });
  const map = new Map(kvArray);

  return Array.from(map.values());
}

function cubicOverlap(cubicBuildings, osmBuildings) {
  return new Promise(function (resolve, reject) {
    var dominantBuildings = [];
    var buildingsToDelete = [];

    if (
      !cubicBuildings ||
      !cubicBuildings.length ||
      cubicBuildings.length === 0
    ) {
      resolve({
        buildingsToDelete: buildingsToDelete,
        dominantBuildings: dominantBuildings,
      });
      return false;
    }
    osmBuildings.features.forEach((osmBuilding, index) => {
      var buildingRoomates = cubicBuildings.filter((cubicBuilding) => {
        var contained = geoContains(osmBuilding.geometry, [
          cubicBuilding.Longitude,
          cubicBuilding.Latitude,
        ]);

        return contained;
      });
      if (buildingRoomates && buildingRoomates.length === 1) {
        dominantBuildings.push(buildingRoomates[0]);
      } else if (buildingRoomates && buildingRoomates.length > 1) {
        buildingRoomates.sort((a, b) => {
          return b.createdAt - a.createdAt;
        });
        buildingRoomates.sort((a, b) => {
          return (b.avails.length || 0) - (a.avails.length || 0);
        });

        var dominantFound = false;
        var deletedFound = false;
        var concatBoth = dominantBuildings.concat(buildingsToDelete);
        for (var buildingRoomate of buildingRoomates) {
          if (
            !concatBoth.some((concatBuilding) => {
              return buildingRoomate.id === concatBuilding.id;
            })
          ) {
            if (
              (buildingRoomate.avails && buildingRoomate.avails > 0) ||
              (!dominantFound &&
                buildingRoomate.Size &&
                buildingRoomate.Size > 0) ||
              (buildingRoomate.Footprint && buildingRoomate.Footprint > 0)
            ) {
              // buildingRoomate.buddies = buildingRoomates
              dominantBuildings.push({ id: buildingRoomate.id });
              dominantFound = true;
            } else if (
              dominantFound === true &&
              (!buildingRoomate.avails || buildingRoomate.avails.length === 0)
            ) {
              deletedFound = true;
              // buildingRoomate.buddies = buildingRoomates
              buildingsToDelete.push({ id: buildingRoomate.id });
            }
          }
        }
      }
    });

    resolve({
      dominantBuildings: dominantBuildings,
      buildingsToDelete: buildingsToDelete,
    });
  });
}

function overLap(osmBuildings, cubicBuildings, mapRef, lightBoxStructures) {
  return new Promise(async function (resolve, reject) {
    var axiosArray = [];
    var osmKeepers = [];
    var tilePromises = []; // this is used to hold promises for new tiles so we can update teh entire array of buldings after all requests tiles have been completed
    var tileCookies = [];
    var searchCookies = [];
    var newOSMBuildings = 0;

    var progressOSM = 0;
    // alert(osmBuildings.features.length)

    osmBuildings.features.forEach((osmBuilding, index) => {
      var esriCoords = polylabel(osmBuilding.geometry.coordinates);
      var latitude = esriCoords[1];
      var longitude = esriCoords[0];
      var buildingOverlap;

      if (cubicBuildings && cubicBuildings.length > 0) {
        buildingOverlap = cubicBuildings.some((cubicBuilding, index) => {
          if (
            geoContains(osmBuilding.geometry, [
              cubicBuilding.Longitude,
              cubicBuilding.Latitude,
            ]) ||
            (Math.abs(latitude - cubicBuilding.Latitude) < 0.001 &&
              Math.abs(longitude - cubicBuilding.Longitude) < 0.0001)
          ) {
            // cubicBuildings[index].Longitude = polylabel(osmBuilding.geometry.coordinates)[0]
            // cubicBuildings[index].Latitude = polylabel(osmBuilding.geometry.coordinates)[1]
            // //test - console.log("osm name", osmBuilding.properties.name, "cubic", JSON.stringify(cubicBuilding))
            return true;
          } else if (
            osmBuildings.features.indexOf((osmBuildingFromNewList) => {
              var centerNew = polylabel(
                osmBuildingFromNewList.geometry.coordinates
              );
              var centerCurrent = polylabel(osmBuilding.geometry.coordinates);

              return (
                Math.abs(centerNew[0] - centerCurrent[0]) < 0.001 &&
                Math.abs(centerNew[1] - centerCurrent[1]) < 0.001
              );
            }) >= 0
          ) {
            //if the new osmBuilding overlaps another new osmBuilding we can't add two, then make it overlap -- this is to prevent duplicates being added within the same new data set
            return true;
          } else {
            return false;
            // only look for ones taht are in there
          }
        });
      }

      if (!buildingOverlap) {
        var closestTileIndex = 0;
        var geojsonArea = require("@mapbox/geojson-area");
        if (geojsonArea.geometry(osmBuilding.geometry) * 10.7639 > 5000) {
          if (
            !osmBuilding.properties.addr_street ||
            !osmBuilding.properties.addr_housenumber ||
            !osmBuilding.properties.addr_city ||
            !osmBuilding.properties.addr_state
          ) {
            var newbuilding = newBuilding(
              osmBuilding,
              esriCoords,
              false,
              index
            );
            progressOSM++;

            var cubicIndex = osmKeepers.push(newbuilding) - 1;
            axiosArray.push({
              cubicIndex: cubicIndex,
              Longitude: longitude,
              Latitude: latitude,
            }); // these need to be geocoded
          } else {
            var newbuilding = newBuilding(osmBuilding, esriCoords, true, index);
            // cubicBuildings.push(newbuilding)
            osmKeepers.push(newbuilding); // these have addresses
            progressOSM++;
          }
        }
      } else {
        // we need to remove the element from the cubic array
        // //test - console.log("yes overlap")
      }
    });

    // if (((osmBuildings.length - 1) / progressOSM) === 1) {
    //test - console.log("osmTotal" + osmBuildings.features.length)
    //test - console.log("Keepers " + osmKeepers.length)

    axiosArray = unique(axiosArray, ["Latitude", "Longitude"]); // these ones need streets and addresses - they wil next be batch geocoded
    osmKeepers = unique(osmKeepers, ["Latitude", "Longitude"]); // these ones already have addresses and have been converted to new buildings

    if (axiosArray.length > 0) {
      var batchGeoResults = await axios
        .post(process.env.REACT_APP_DATABASE + "api/batchGeocode", {
          axiosArray: axiosArray,
        })
        .catch((error) => {
          console.log(error);
        });

      if (batchGeoResults) {
        batchGeoResults.data.forEach((geoResult, index) => {
          // add in the newly geocoded results into the list

          if (osmKeepers[geoResult.cubicIndex]) {
            osmKeepers[geoResult.cubicIndex].StreetName =
              geoResult.StreetName || "";
            osmKeepers[geoResult.cubicIndex].StreetNumber =
              geoResult.StreetNumber || "";
            osmKeepers[geoResult.cubicIndex].City = geoResult.City || "";
            osmKeepers[geoResult.cubicIndex].State = geoResult.State || "";
            // osmKeepers[geoResult.cubicIndex].County = geoResult.County
            osmKeepers[geoResult.cubicIndex].Country = geoResult.Country || "";
            osmKeepers[geoResult.cubicIndex].Zip = geoResult.Zip || "";
          }
        });
      }

      osmKeepers.forEach((osmKeeper, index) => {
        //  these are the new buildings
        // if (!osmKeeper.BuildingHeight || (osmKeeper.BuildingHeight === "") || (osmKeeper.BuildingHeight === 0) || !osmKeeper.NumberFloors || (osmKeeper.NumberFloors === "") || (osmKeeper.NumberFloors === 0)) {

        osmBuildings.features.some((oneFeature) => {
          // we are seeing if the new buildings are contained by the OSM buildings that we downloaded.
          // These came from the downloadeed buidlings, so they should match.This is probalby to make sure teh geocoding properly worked, it also gets the height.some of these will be true, but we are not keeping track

          var containMe = geoContains(oneFeature.geometry, [
            osmKeeper.Longitude,
            osmKeeper.Latitude,
          ]);

          if (containMe) {
            if (
              oneFeature.properties.height &&
              (!osmKeeper.BuildingHeight ||
                osmKeeper.BuildingHeight === "" ||
                osmKeeper.BuildingHeight === 0)
            ) {
              osmKeepers[index].BuildingHeight =
                parseFloat(oneFeature.properties.height) * 3.28084;
            }
            if (
              oneFeature.properties.type &&
              (!osmKeeper.PropertyType || osmKeeper.PropertyType === "")
            ) {
              osmKeepers[index].PropertyType = oneFeature.properties.type;
            } else {
              // if we don't do this we may not be able to see the building, and it may keep trying to fill this one
              // 20 doesn't work, it shows up too much and is confusing... trying with null - 7/23 update
              if (oneFeature.properties.levels > 0) {
                osmKeepers[index].BuildingHeight =
                  parseInt(oneFeature.properties.levels) * 15;
              } else {
                osmKeepers[index].BuildingHeight = 0;
              }
              // this is a guess if the height is null, it is the average height of a warehouse
            }
            if (
              oneFeature.properties.levels &&
              (!osmKeeper.NumberFloors ||
                osmKeeper.NumberFloors === "" ||
                osmKeeper.NumberFloors === 0)
            ) {
              osmKeepers[index].NumberFloors = parseInt(
                oneFeature.properties.levels
              );
            } else {
              if (
                osmKeepers[index].BuildingHeight > 40 &&
                osmKeepers[index].type &&
                !osmKeepers[index].type.includes("Residential")
              ) {
                osmKeepers[index].NumberFloors = parseInt(
                  osmKeepers[index].BuildingHeight / 16
                );
              }
            }

            // cubicBuildings[index].Longitude = polylabel(osmBuilding.geometry.coordinates)[0]
            // cubicBuildings[index].Latitude = polylabel(osmBuilding.geometry.coordinates)[1]
            return true;
          }
        });

        // }
      });

      // we can let this go without waiting
      axios
        .post(process.env.REACT_APP_DATABASE + "api/bulkBuildingInsert", {
          cubicBuildings: osmKeepers,
        })
        .then((buildingsCreated) => {
          //
          // alert(JSON.stringify(buildingsCreated.data))
        })
        .catch((error) => {
          //
          alert(error);
        });

      resolve(osmKeepers); // we are only returning the new buildings that did not overlap.
      // })
    } else {
      resolve([]);
    }
  });
}

//Converts numeric degrees to radians
Number.prototype.toRad = function () {
  return (this * Math.PI) / 180;
};

function newBuilding(osmBuilding, esriCoords, addressIncluded, index) {
  var height = osmBuilding.properties.height * 3.28084 || null; //parseInt((mapTiles[closestTileIndex][2] + mapTiles[closestTileIndex][3]))
  var numFloors =
    osmBuilding.properties.levels ||
    osmBuilding.properties.building_levels ||
    null;

  var geojsonArea = require("@mapbox/geojson-area");
  var floorSize = parseInt(
    geojsonArea.geometry(osmBuilding.geometry) * 10.7639
  );
  var newbuilding = {};

  if (!addressIncluded) {
    newbuilding = {
      OSM: true,
      id: new Date().getTime() - 1687390000000 + (1000 * index + 1),
      Latitude: esriCoords[1],
      Longitude: esriCoords[0],

      avails: [],
      images: [],
      PropertyType: osmBuilding.properties.type,
      PropertyName: osmBuilding.properties.name,
      CampusName: osmBuilding.properties.official_name,
      Size:
        floorSize < 40000 && numFloors && numFloors < 10
          ? floorSize * (numFloors || 0)
          : null, // polygonArea(osmBuilding.geometry.coordinates ) ,
      Footprint: floorSize,

      YearBuilt: osmBuilding.properties.start_date
        ? new Date(osmBuilding.properties.start_date)
        : null,
      YearRenovated: null,
      NumberFloors: numFloors, //osmBuilding.properties.levels || parseInt((mapTiles[closestTileIndex][2] + mapTiles[closestTileIndex][3])/16),

      Elevation: null, //+ mapTiles[closestTileIndex][3]
      markedDelete: 0,

      BuildingHeight: height,
      FloorToFloor:
        height && osmBuilding.properties.levels
          ? height / osmBuilding.properties.levels
          : null,
      FloorToFloorL1: null,
      FloorToFloorTOP: null,
      ConstructionType: osmBuilding.properties.building_structure,
      LiveLoad: null,
      FreightElevators: null,
      PassengerElevators: null,
      BackupGenerator: null,
      BackupGeneratorKW: null,
      PowerAmps: osmBuilding.properties.power,
      PowerVolts: null,
      RoofScreenHeight: null,
      InitialPurpose: null,
      CurrentPurpose: null,
      PotentialPurpose: null,
      UtilityPad: null,
      SeparatedLabAir: null,
      CoolingSystem: null,
      HVACYear: null,
      RoofYear: null,
      ParkingRatio: null,
      ParkingStyle: null,
      FiberLines: null,
      DockDoors: null,
      GradeDoors: null,
      ConstructionStatus: null,
      MechanicalMezz: null,
      Showers: null,

      Gym: null,
      MeetingRooms: null,
      Expenses: null,
      ExpensesDescription: null,
      OccupancyRating: null,
      OwnerUserOrInvestor: null,

      Notes: osmBuilding.properties.description,

      contacts: [],
      groups: [
        {
          building_group: {
            groupId: 1,
            markedDelete: 0,
            groupPermission: "Edit",
          },
        },
      ],
    };
  } else {
    newbuilding = {
      OSM: true,
      id: new Date().getTime() - 1687390000000 + 1000 * index + 1,
      Latitude: esriCoords[1],
      Longitude: esriCoords[0],

      avails: [],
      images: [],

      StreetName: osmBuilding.properties.addr_street, //+ "- LBS:"+ lightBoxStreet,
      StreetNumber: osmBuilding.properties.addr_housenumber,
      PropertyType: osmBuilding.properties.type,
      PropertyName: osmBuilding.properties.name,
      CampusName: osmBuilding.properties.official_name,
      Size:
        floorSize < 40000 && numFloors && numFloors < 10
          ? floorSize * (numFloors || 0)
          : null, // polygonArea(osmBuilding.geometry.coordinates ) ,
      Footprint: floorSize,
      City: osmBuilding.properties.addr_city, //+ "- LBS:" + lightBoxCity,
      State: osmBuilding.properties.addr_state,
      Zip: osmBuilding.properties.addr_postcode,
      County: osmBuilding.properties.addr_district,
      YearBuilt: osmBuilding.properties.start_date
        ? new Date(osmBuilding.properties.start_date)
        : null,
      YearRenovated: null,
      NumberFloors: numFloors, //osmBuilding.properties.levels || parseInt((mapTiles[closestTileIndex][2] + mapTiles[closestTileIndex][3])/16),

      Elevation: null, //+ mapTiles[closestTileIndex][3]
      // LightBoxHeight: lightBoxHeight,

      markedDelete: 0,

      BuildingHeight: height,
      FloorToFloor:
        height && osmBuilding.properties.levels
          ? height / osmBuilding.properties.levels
          : null,
      FloorToFloorL1: null,
      FloorToFloorTOP: null,
      ConstructionType: osmBuilding.properties.building_structure,
      LiveLoad: null,
      FreightElevators: null,
      PassengerElevators: null,
      BackupGenerator: null,
      BackupGeneratorKW: null,
      PowerAmps: osmBuilding.properties.power,
      PowerVolts: null,
      RoofScreenHeight: null,
      InitialPurpose: null,
      CurrentPurpose: null,
      PotentialPurpose: null,
      UtilityPad: null,
      SeparatedLabAir: null,
      CoolingSystem: null,
      HVACYear: null,
      RoofYear: null,
      ParkingRatio: null,
      ParkingStyle: null,
      FiberLines: null,
      DockDoors: null,
      GradeDoors: null,
      ConstructionStatus: null,
      MechanicalMezz: null,
      Showers: null,

      Gym: null,
      MeetingRooms: null,
      Expenses: null,
      ExpensesDescription: null,
      OccupancyRating: null,
      OwnerUserOrInvestor: null,

      Notes: osmBuilding.properties.description,

      contacts: [],
      groups: [
        {
          building_group: {
            groupId: 1,
            markedDelete: 0,
            groupPermission: "Edit",
          },
        },
      ],
    }; // make a copy
  }

  return newbuilding;
}

function degrees_to_radians(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}

function latLngToTileXY(lat, lng, zoom) {
  var lat_rad = degrees_to_radians(lat);
  var n = Math.pow(2, zoom);
  var xtile = parseInt(n * ((lng + 180) / 360));
  var ytile = parseInt(
    (n * (1 - Math.log(Math.tan(lat_rad) + 1 / Math.cos(lat_rad)) / Math.PI)) /
      2
  );

  var result = {
    x: xtile,
    y: ytile,
  };
  return result;
}

function updateBuildingHeightSpecific(cubicBuildings) {
  var results = cubicBuildings;
  var tileArray = [];

  results.map((buildingMissingHeight) => {
    var newTile = latLngToTileXY(
      buildingMissingHeight.Latitude,
      buildingMissingHeight.Longitude,
      15
    );
    var tileExistsAlready = tileArray.some((existingTile) => {
      return existingTile.x === newTile.x && existingTile.y === newTile.y;
    });
    if (!tileExistsAlready) {
      tileArray.push(newTile);
    }
  });

  //test - console.log("Results " + results.length)
  //test - console.log("Tiles " + tileArray.length)
  var tilePromiseArray = [];
  tileArray.map((tileToFetch) => {
    var qs =
      "https://data.osmbuildings.org/0.2/anonymous/tile/15/" +
      tileToFetch.x +
      "/" +
      tileToFetch.y +
      ".json";
    //test - console.log(qs)
    var myPromise = axios
      .get(qs)
      .catch((error) => console.log("Error in getTiles" + error.message));
    tilePromiseArray.push(myPromise);
  });

  Promise.all(tilePromiseArray)
    .then((tileDataArrays) => {
      //test - console.log("Data ARrays")
      //test - console.log(tileDataArrays)
      tileDataArrays.map((tilePointDataArray) => {
        //test - console.log("tile Point Dta Array")
        //test - console.log(tilePointDataArray)
        tilePointDataArray.data.features.map((tilePointData) => {
          results.map((cubicBuilding) => {
            var containMe = geoContains(tilePointData.geometry, [
              cubicBuilding.Longitude,
              cubicBuilding.Latitude,
            ]);
            if (containMe) {
              var qs =
                process.env.REACT_APP_DATABASE +
                "api/updateBuildingHeightSpecific";

              //test - console.log("updateing building height specific")
              //test - console.log(qs)
              axios
                .post(
                  qs,
                  {
                    building: {
                      id: cubicBuilding.id,
                      PropertyName: cubicBuilding.PropertyName,
                      PropertyType: cubicBuilding.PropertyType,
                      NumberFloors: cubicBuilding.NumberFloors,
                      BuildingHeight: cubicBuilding.BuildingHeight,
                    },
                    tilePointData: tilePointData,
                  },
                  { timeout: 1000 }
                )
                .catch((error) => {
                  //test - console.log("updating building height taking a while, we move mon")
                });
            }
          });
        });
      });
    })
    .catch((error) => {
      //test - console.log("failed in Promise All: " + error)
    });
}

// export function getBuildingFromLatLon(latitude, longitude) {
//   return new Promise(async function (resolve, reject) {
//     var newTile = latLngToTileXY(latitude, longitude, 16);
//     var qs =
//       "https://data.osmbuildings.org/0.2/anonymous/tile/16/" +
//       newTile.x +
//       "/" +
//       newTile.y +
//       ".json";
//     //test - console.log(qs)
//     var buildings = await axios
//       .get(qs)
//       .catch((error) =>
//         console.log("Error in getBuildingFromLatLon" + error.message)
//       );

//     var containMe = false;

//     var earth = 6378.137, //radius of the earth in kilometer
//       pi = Math.PI,
//       m = 1 / (((2 * pi) / 360) * earth) / 1000; //1 meter in degree

//     var new_latitude1 = latitude + 10 * m;
//     var new_latitude2 = latitude - 10 * m;

//     var earth = 6378.137, //radius of the earth in kilometer
//       pi = Math.PI,
//       cos = Math.cos,
//       m = 1 / (((2 * pi) / 360) * earth) / 1000; //1 meter in degree

//     var new_longitude1 = longitude + (10 * m) / cos(latitude * (pi / 180));
//     var new_longitude2 = longitude - (10 * m) / cos(latitude * (pi / 180));

//     if (
//       buildings &&
//       buildings.data &&
//       buildings.data.features &&
//       buildings.data.features.length > 0
//     ) {
//       buildings.data.features.some((building) => {
//         containMe =
//           geoContains(building.geometry, [longitude, latitude]) ||
//           geoContains(building.geometry, [new_longitude1, latitude]) ||
//           geoContains(building.geometry, [new_longitude2, latitude]) ||
//           geoContains(building.geometry, [longitude, new_latitude1]) ||
//           geoContains(building.geometry, [longitude, new_latitude2]);
//         if (containMe) {
//           //test - console.log(building) // what is the height field?  if its empty we may have to search cubic search buildings
//           resolve(building);
//           return containMe;
//         }
//       });
//     } else {
//       reject({ reason: "Some not valid" });
//     }
//     if (!containMe) {
//       reject({ reason: "Not found" });
//     }
//   });
// }
export async function getBuildingFromLatLon(latitude, longitude) {
  try {
    const buildings = await axios
      .post(
        process.env.REACT_APP_DATABASE + "api/getBuildingFromLatLon",
        { Latitude: latitude, Longitude: longitude },
        { timeout: 2000 }
      )
      .catch((error) => {
        console.log(error);
      });

    if (buildings?.length > 0) {
      return buildings[0];
    }

    // Define the radius in meters
    const radius = 10;

    // Construct the Overpass API query
    const overpassQuery = `
        [out:json];
        (
          way["building"](around:${radius},${latitude},${longitude});
          relation["building"](around:${radius},${latitude},${longitude});
        );
        out body;
        >;
        out skel qt;
      `;

    // Encode the query for URL inclusion
    const encodedQuery = encodeURIComponent(overpassQuery.trim());

    // Overpass API endpoint
    const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodedQuery}`;

    // Fetch data from Overpass API
    const response = await axios.get(overpassUrl);

    // Check if any elements were returned
    if (response.data.elements && response.data.elements.length > 0) {
      // Return the first matching building element
      return response.data.elements[0];
    } else {
      //   throw new Error("No building found at the specified location.");
      return null;
    }
  } catch (error) {
    console.error("Error in getBuildingFromLatLon:", error.message);
    // throw error;
    return;
  }
}

export function mobileAndTabletCheck() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Simplified regex for common mobile and tablet devices
  const regex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  const isMobileTabletUA = regex.test(userAgent);
  const isMobileTabletScreen = window.matchMedia("(max-width: 1100px)").matches;

  return isMobileTabletUA || isMobileTabletScreen;
}

function getPointByDistance(pnts, distance) {
  var cl = 0;
  var ol;
  var result;
  pnts.forEach(function (point, i, points) {
    ol = cl;
    cl += i ? lineLen([points[i - 1], point]) : 0;
    if (distance <= cl && distance > ol) {
      var dd = distance - ol;
      result = pntOnLine([points[i - 1], point], dd);
    }
  });
  return result;
}
// returns a point on a single line (two points) using distance // line=[[x0,y0],[x1,y1]]
function pntOnLine(line, distance) {
  var t = distance / lineLen(line);
  var xt = (1 - t) * line[0][0] + t * line[1][0];
  var yt = (1 - t) * line[0][1] + t * line[1][1];
  return [xt, yt];
}
// returns the total length of a linestring (multiple points) // pnts=[[x0,y0],[x1,y1],[x2,y2],...]
function totalLen(pnts) {
  var tl = 0;
  pnts.forEach(function (point, i, points) {
    tl += i ? lineLen([points[i - 1], point]) : 0;
  });
  return tl;
}
// returns the length of a line (two points) // line=[[x0,y0],[x1,y1]]
function lineLen(line) {
  var xd = line[0][0] - line[1][0];
  var yd = line[0][1] - line[1][1];
  return Math.sqrt(xd * xd + yd * yd);
}

function refreshMapProperties(
  searchResults,
  elevationFactorState,
  viewportElevation,
  groundElevation,
  filters
) {
  // return new Promise(async function (resolve, reject) {

  console.log("refresh start " + new Date().getSeconds());

  var coordsData = [];
  var coordsDataLines = [];
  var coordsDataCircles = [];

  var viewportElevationToUse;

  if (viewportElevation) {
    viewportElevationToUse = viewportElevation.elevation;
  } else {
    viewportElevationToUse = 5; // await getViewportElevation(mapRef, searchResults[0])
  }

  if (
    !searchResults
    // || searchResults.length === 0
  ) {
    // we may have cleared the properties so let it go
    // reject({ reason: "empty" })
    return false;
  }

  searchResults.map((result) => {
    // var theElevation = result.Elevation ? parseFloat(result.Elevation) : 0
    var theBuildingHeight = result.BuildingHeight
      ? parseFloat(result.BuildingHeight)
      : 0;
    // var HeightFactor = elevationFactorState
    // console.log("cow")

    coordsDataLines.push({
      type: "Feature",
      id: result.id,
      avails: result.avails,
      images: result.images,
      NumberFloors: result.NumberFloors,
      Latitude: result.Latitude,
      Longitude: result.Longitude,
      Elevation: result.Elevation,
      elevationHeight: 40 * elevationFactorState,
      mobileElevationHeight:
        viewportElevationToUse +
        theBuildingHeight / 3.28 +
        (20 * elevationFactorState + 1),

      // + theBuildingHeight * HeightFactor / 3.28
      BuildingHeight: result.BuildingHeight,
      properties: {
        CampusName: result.CampusName,
        Size: result.Size,
        Footprint: result.Footprint,
        PropertyName: result.PropertyName, //nfObject.format(parseInt((geojsonArea.geometry(esriShape.geometry) * 10.7639)))
        StreetName: result.StreetName,
        StreetNumber: result.StreetNumber,
        City: result.City,
        State: result.State,
        summary: getMarkerInfo(result),
      },
      // "geometry": {
      //     "type": "Polygon",
      //     "coordinates": [
      //         [[result.Longitude + .0001, result.Latitude + .0001], [result.Longitude - .0001, result.Latitude + .0001],
      //         [result.Longitude - .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude + .0001]]
      //     ]
      // },
      geometry: {
        type: "Point",

        coordinates: (() => {
          // if (!mobileAndTabletCheck()) {
          // alert(parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.2084)
          // return ([result.Longitude, result.Latitude])
          var ElevationFactor = elevationFactorState;
          var HeightFactor = elevationFactorState;

          var point;

          if (mobileAndTabletCheck()) {
            point = [result.Longitude, result.Latitude];
            // , theElevation * ElevationFactor +
            // theBuildingHeight * HeightFactor / 3.28]//3.28084]
          } else {
            point = [result.Longitude, result.Latitude];
            // , theElevation * ElevationFactor +
            // theBuildingHeight * HeightFactor / 3.28]//3.28084]
          }
          // //test - console.log(JSON.stringify(point))
          // //test - console.log(result.id)

          return point;
          // }
          // else {
          // var point = [result.Longitude, result.Latitude, parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.28084]//3.28084]

          // return point
          // }
        })(),
      },
    });
    // console.log("chick")

    coordsDataCircles.push({
      type: "Feature",
      id: result.id,
      avails: result.avails,
      images: result.images,
      NumberFloors: result.NumberFloors,
      Latitude: result.Latitude,
      Longitude: result.Longitude,
      Elevation: result.Elevation,
      elevationHeight: groundElevation * elevationFactorState,
      BuildingHeight: result.BuildingHeight,
      properties: {
        CampusName: result.CampusName,
        Size: result.Size,
        Footprint: result.Footprint,
        PropertyName: result.PropertyName, //nfObject.format(parseInt((geojsonArea.geometry(esriShape.geometry) * 10.7639)))
        StreetName: result.StreetName,
        StreetNumber: result.StreetNumber,
        City: result.City,
        State: result.State,
        summary: getMarkerInfo(result),
      },
      // "geometry": {
      //     "type": "Polygon",
      //     "coordinates": [
      //         [[result.Longitude + .0001, result.Latitude + .0001], [result.Longitude - .0001, result.Latitude + .0001],
      //         [result.Longitude - .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude - .0001], [result.Longitude + .0001, result.Latitude + .0001]]
      //     ]
      // },
      geometry: {
        type: "Point",

        coordinates: (() => {
          // if (!mobileAndTabletCheck()) {
          // alert(parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.2084)
          // return ([result.Longitude, result.Latitude])
          var ElevationFactor = elevationFactorState;
          var HeightFactor = elevationFactorState;

          var point;
          var theElevation = result.Elevation
            ? parseFloat(result.Elevation)
            : 0;
          var theBuildingHeight = result.BuildingHeight
            ? parseFloat(result.BuildingHeight)
            : 0;
          if (mobileAndTabletCheck()) {
            point = [result.Longitude, result.Latitude, 3];
            // , theElevation * ElevationFactor +
            // theBuildingHeight * HeightFactor / 3.28]//3.28084]
          } else {
            point = [result.Longitude, result.Latitude, 3];
            // , theElevation * ElevationFactor +
            // theBuildingHeight * HeightFactor / 3.28]//3.28084]
          }
          // //test - console.log(JSON.stringify(point))
          // //test - console.log(result.id)

          return point;
          // }
          // else {
          // var point = [result.Longitude, result.Latitude, parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.28084]//3.28084]

          // return point
          // }
        })(),
      },
    });

    coordsData.push({
      type: "Feature",
      id: result.id,
      avails: result.avails,
      images: result.images,
      NumberFloors: result.NumberFloors,
      Latitude: result.Latitude,
      Longitude: result.Longitude,
      Elevation: result.Elevation,
      elevationHeight: groundElevation * elevationFactorState * 0.99,

      BuildingHeight: result.BuildingHeight,
      properties: {
        CampusName: result.CampusName,
        Size: result.Size,
        Footprint: result.Footprint,
        PropertyName: result.PropertyName, //nfObject.format(parseInt((geojsonArea.geometry(esriShape.geometry) * 10.7639)))
        StreetName: result.StreetName,
        StreetNumber: result.StreetNumber,
        City: result.City,
        State: result.State,
        summary: getMarkerInfo(result),
      },

      geometry: {
        type: "Point",

        coordinates: (() => {
          var point;

          var theBuildingHeight = result.BuildingHeight
            ? parseFloat(result.BuildingHeight)
            : 0;
          if (mobileAndTabletCheck()) {
            point = [
              result.Longitude,
              result.Latitude,
              viewportElevationToUse + theBuildingHeight / 3.28 + (20 + 1),
            ]; //3.28084]
          } else {
            point = [
              result.Longitude,
              result.Latitude,
              40 * elevationFactorState,
            ]; //groundElevation * elevationFactorState +1]
            // , theElevation * ElevationFactor +
            // theBuildingHeight * HeightFactor / 3.28]//3.28084]
          }
          // //test - console.log(JSON.stringify(point))
          // //test - console.log(result.id)

          return point;
          // }
          // else {
          // var point = [result.Longitude, result.Latitude, parseInt(result.Elevation) + parseFloat(result.BuildingHeight) / 3.28084]//3.28084]

          // return point
          // }
        })(),
      },
    });
  });

  if (coordsData) {
    coordsData.sort((a, b) => {
      var availA = filters.availablesCheck
        ? a.properties.summary.onMarketAvailSF +
          a.properties.summary.offMarketAvailSF
        : 0;
      var compA = filters.compsCheck
        ? a.properties.summary.onMarketCompsSF +
          a.properties.summary.offMarketCompsSF
        : 0;
      var availB = filters.availablesCheck
        ? b.properties.summary.onMarketAvailSF +
          b.properties.summary.offMarketAvailSF
        : 0;
      var compB = filters.compsCheck
        ? b.properties.summary.onMarketCompsSF +
          b.properties.summary.offMarketCompsSF
        : 0;
      return availA + compA - (availB + compB);
    });

    var middleIndex = parseInt(coordsData.length / 2);
    var middleValue =
      (coordsData && coordsData[middleIndex] && filters.availablesCheck
        ? coordsData[middleIndex].properties.summary.onMarketAvailSF +
          coordsData[middleIndex].properties.summary.offMarketAvailSF
        : 0) +
      (coordsData && coordsData[middleIndex] && filters.compsCheck
        ? coordsData[middleIndex].properties.summary.onMarketCompsSF +
          coordsData[middleIndex].properties.summary.offMarketCompsSF
        : 0);
    coordsData.forEach(function (value, i) {
      var eachValAvail = filters.availablesCheck
        ? coordsData[i].properties.summary.onMarketAvailSF +
          coordsData[i].properties.summary.offMarketAvailSF
        : 0;
      var eachValComp = filters.compsCheck
        ? coordsData[i].properties.summary.onMarketCompsSF +
          coordsData[i].properties.summary.offMarketCompsSF
        : 0;

      if (coordsData.length < 8) {
        coordsData[i].properties.summary.rank = "big";
      } else if (
        middleValue - (eachValAvail + eachValComp) / middleValue <
        0.25
      ) {
        coordsData[i].properties.summary.rank = "medium";
      } else {
        if (i < coordsData.length / 3) {
          coordsData[i].properties.summary.rank = "small";
        } else if (
          i >= coordsData.length / 3 &&
          i <= (2 * coordsData.length) / 3
        ) {
          coordsData[i].properties.summary.rank = "medium";
        } else {
          coordsData[i].properties.summary.rank = "big";
        }
      }
    });
  }
  coordsData.sort((a, b) => {
    return b.Latitude - a.Latitude;
  });

  coords = coordsData;
  coordsLines = coordsDataLines;
  coordsCircles = coordsDataCircles;

  console.log("refresh end " + new Date().getSeconds());

  // resolve(true)
  // })
}

export async function getCities(
  myViewport,
  theViewState,
  elevationFactorState,
  viewportElevation
) {
  return new Promise(async function (resolve, reject) {
    var groundElevation;
    if (!myViewport) {
      resolve(false);
      return null;
    }
    var latitude1 = myViewport._ne.lat;
    var longitude1 = myViewport._ne.lng;
    var latitude2 = myViewport._sw.lat;
    var longitude2 = myViewport._sw.lng;

    var nodestring =
      "(" +
      latitude2 +
      "," +
      longitude2 +
      "," +
      latitude1 +
      "," +
      longitude1 +
      ")";

    // var groundElevation = 0
    var citiesTypes = '"place"~"city"';

    if (theViewState.zoom <= 12) {
      citiesTypes = '"place"~"city|town|suburb|village|neighbourhood|quarter"';
    } else if (theViewState.zoom <= 13) {
      citiesTypes = '"place"~"city|town|village|neighbourhood|"';
    } else if (theViewState.zoom > 13) {
      citiesTypes = '"place"~"city|town|village|suburb|neighbourhood|quarter"';
    } else {
      citiesTypes = '"place"~"city"';
    }

    var populationMin = "100000";

    if (theViewState.zoom > 12) {
      populationMin = "30000";
    }
    if (theViewState.zoom > 10) {
      populationMin = "50000";
    }

    var populationConstraint =
      '(if:number(t["population"])>' + populationMin + ")";
    if (theViewState.zoom > 15) {
      populationConstraint = "";
    }

    var elevationCity = 1000;

    var sampleScreenCities =
      "https://www.overpass-api.de/api/interpreter?" +
      "data=[out:json];node[" +
      citiesTypes +
      "][name]" +
      nodestring +
      populationConstraint +
      encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") +
      "out%20geom; ";

    if (theViewState.zoom < 7) {
      sampleScreenCities =
        "https://www.overpass-api.de/api/interpreter?" +
        'data=[out:json];node["place"="state"]' +
        encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") +
        "out%20geom; ";
    }

    if (theViewState.zoom < 5) {
      sampleScreenCities =
        "https://www.overpass-api.de/api/interpreter?" +
        'data=[out:json];node["place"="country"]' +
        encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") +
        "out%20geom; ";
    }

    //test - console.log(sampleScreenCities)
    var validCities = true;
    // setShowWait(true)

    // var cities = await axios.get(sampleScreenCities, {
    //     signal: newCitiesController.signal
    // })

    var cities = await getAxios(sampleScreenCities, { newCitiesMaster }).catch(
      (error) => {
        console.log(error);
        validCities = false;
        reject(error);

        return false;
      }
    );

    // setShowWait(false)

    var citiesReduced = [];
    var citiesData = [];

    if (cities) {
      cities.data.elements.map((citiesFeature, index) => {
        if (
          citiesFeature &&
          citiesFeature.tags &&
          citiesFeature.tags.name &&
          ((theViewState.zoom <= 10 &&
            parseFloat(citiesFeature.tags.population) > 200000) ||
            theViewState.zoom > 3)
        ) {
          var citiesFeatureReducedNameIndex = citiesReduced.findIndex(
            (citiesReduced) => {
              return citiesReduced.name === citiesFeature.tags.name;
            }
          );
          if (citiesFeatureReducedNameIndex >= 0) {
            citiesReduced[citiesFeatureReducedNameIndex].features.push(
              citiesFeature
            );
          } else {
            citiesReduced.push({
              name: citiesFeature.tags["name:en"] || citiesFeature.tags.name,
              features: [citiesFeature],
            });
          }
        } else if (
          citiesFeature &&
          citiesFeature.tags &&
          citiesFeature.tags.name &&
          ((theViewState.zoom >= 10 &&
            theViewState.zoom <= 13 &&
            parseFloat(citiesFeature.tags.population) > 15000) ||
            theViewState.zoom > 13)
        ) {
          var citiesFeatureReducedNameIndex = citiesReduced.findIndex(
            (citiesReduced) => {
              return (
                citiesReduced.name === citiesFeature.tags.name ||
                citiesReduced.name === citiesFeature.tags["name:en"]
              );
            }
          );
          if (citiesFeatureReducedNameIndex >= 0) {
            citiesReduced[citiesFeatureReducedNameIndex].features.push(
              citiesFeature
            );
          } else {
            citiesReduced.push({
              name: citiesFeature.tags["name:en"] || citiesFeature.tags.name,
              features: [citiesFeature],
            });
          }
        }
      });

      // if ((theViewState.zoom > 12)) {
      console.log(new Date().getSeconds());
      console.log(new Date().getMilliseconds());
      elevationCity = await axios
        .get(
          process.env.REACT_APP_DATABASE +
            "api/getElevationLocal?longitude=" +
            (longitude1 + longitude2) / 2 +
            "&latitude=" +
            (latitude1 + latitude2) / 2,
          { timeout: 2000 }
        )
        .catch((error) => {
          console.log(error);
        });

      console.log(new Date().getSeconds());
      console.log(new Date().getMilliseconds());

      if (
        elevationCity &&
        elevationCity.data.elevation !== "not found" &&
        validCities
      ) {
        groundElevation = parseFloat(elevationCity.data.elevation);
      } else {
        // groundElevation = 3
      }
      // }

      citiesReduced.map((city) => {
        var point = [
          city.features[0].geometry.coordinates[0],
          city.features[0].geometry.coordinates[1],
        ];

        var zCord = groundElevation * elevationFactorState; //+ Math.pow((20 - mapRef.current.deck.viewState["default-view"].zoom), 3)
        if (groundElevation > 50) {
          zCord = zCord + 20;
        }
        //  alert(zCord)
        citiesData.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              point[0],
              point[1],
              mobileAndTabletCheck() ? 200 + viewportElevation.elevation : 100,
            ],
          },
          properties: city.features[0].tags,
          name: city.name,
        });
      });

      citiesOnMap = citiesData;
      // alert("we have cities")
      resolve(true);
    } else {
      // alert("No Cities")
      resolve(false);
    }
  });
}

async function getStreets(
  myViewport,
  theViewState,
  groundElevation,
  elevationFactorState,
  viewportElevation
) {
  return new Promise(async function (resolve, reject) {
    if (!myViewport) {
      resolve(false);
      return null;
    }
    var latitude1 = myViewport._ne.lat;
    var longitude1 = myViewport._ne.lng;
    var latitude2 = myViewport._sw.lat;
    var longitude2 = myViewport._sw.lng;

    var nodestring =
      "(" +
      latitude2 +
      "," +
      longitude2 +
      "," +
      latitude1 +
      "," +
      longitude1 +
      ")";
    var streetTypes = '"highway"~"motorway"';

    if (theViewState.zoom <= 13 - groundElevation.elevation / 100) {
      streetTypes = '"highway"~"motorway"';
    } else if (theViewState.zoom <= 14.5 - groundElevation.elevation / 100) {
      streetTypes = '"highway"~"motorway|trunk"';
    } else if (theViewState.zoom <= 16 - groundElevation.elevation / 100) {
      streetTypes =
        '"highway"~"motorway|trunk|primary|secondary|tertiary|unclassified"';
    } else if (theViewState.zoom > 16 - groundElevation.elevation / 100) {
      streetTypes =
        '"highway"~"motorway|trunk|primary|secondary|tertiary|unclassified|residential"';
    } else {
      streetTypes = '"highway"~"motorway"';
    }
    var sampleScreenHighwayCount =
      "https://www.overpass-api.de/api/interpreter?" +
      "data=[out:json];way[" +
      streetTypes +
      "][name]" +
      nodestring +
      // ";way[bridge:structure=*]" + nodestring +
      encodeURI(";") +
      "out%20count; ";

    var streetsCount = await axios
      .get(sampleScreenHighwayCount, { timeout: 1000 })
      .catch((error) => {
        console.log(error);
        // alert("Timeout due to slow internet...")
      });

    if (
      streetsCount &&
      streetsCount.data.elements &&
      streetsCount.data.elements[0].tags.total > 100
    ) {
      streetTypes = '"highway"~"motorway|trunk|primary"';
    }

    var sampleScreenHighway =
      "https://www.overpass-api.de/api/interpreter?" +
      "data=[out:json];way[" +
      streetTypes +
      "][name]" +
      nodestring +
      // ";way[bridge:structure=*]" + nodestring +
      encodeURI(";convert item ::=::,::geom=geom(),_osm_type=type();") +
      "out%20geom; ";

    //test - console.log(sampleScreenHighway)
    var streets = {
      data: {
        elements: [],
      },
    };

    // if (aboveOrBelow() > 13) {
    //setShowWWait(true)
    streets = await axios
      .get(sampleScreenHighway, { timeout: 1000 })
      .catch((error) => {
        console.log(error);
      });

    var streetsReduced = [];
    if (streets) {
      streets.data.elements.map((streetFeature, index) => {
        {
          if (streetFeature.tags.highway != "residential")
            if (
              streetFeature &&
              streetFeature.tags &&
              streetFeature.tags.ref &&
              !streetFeature.tags.ref.includes(";")
            ) {
              // if it has a ref see if it is in there or needs to be added
              var streetFeatureReducedRefIndex = streetsReduced.findIndex(
                (streetReduced) => {
                  return streetReduced.ref === streetFeature.tags.ref;
                }
              );
              if (streetFeatureReducedRefIndex >= 0) {
                streetsReduced[streetFeatureReducedRefIndex].features.push(
                  streetFeature
                );
              } else {
                const terms = ["US ", "I ", "CA "];
                const str = streetFeature.tags.ref;

                // check if the string has some of the terms
                const result1 = terms.some((term) => str.includes(term));

                streetsReduced.push({
                  name: result1
                    ? streetFeature.tags.ref
                    : streetFeature.tags.name,
                  ref: streetFeature.tags.ref,
                  features: [streetFeature],
                });
              }
            } else if (
              streetFeature &&
              streetFeature.tags &&
              !streetFeature.tags.ref &&
              streetFeature.tags.name &&
              !streetFeature.tags.name.includes(";")
            ) {
              var streetFeatureReducedNameIndex = streetsReduced.findIndex(
                (streetReduced) => {
                  return streetReduced.name === streetFeature.tags.name;
                }
              );
              if (streetFeatureReducedNameIndex >= 0) {
                streetsReduced[streetFeatureReducedNameIndex].features.push(
                  streetFeature
                );
              } else {
                streetsReduced.push({
                  name: streetFeature.tags.name,
                  features: [streetFeature],
                });
              }
            }
        }
      });
    }

    var streetsData = [];
    var streetsLineData = [];
    var qsElevation = "https://api.open-elevation.com/api/v1/lookup?locations=";
    var divisor = parseInt(streetsData.length / 10);
    if (divisor === 0) {
      divisor = 1;
    }
    // alert(divisor)
    streetsReduced.map((street, index) => {
      var middle = parseInt(street.features.length / 2);
      var points = street.features[middle].geometry.coordinates;
      var totalLength = totalLen(points);
      var midDistance = totalLength / 2;
      var midPoint = getPointByDistance(points, midDistance);
      var prevPoint = 0;
      if (points.len > 0) {
        prevPoint = middle - 1;
      }

      var angle =
        (Math.atan2(
          points[0][1] - points[points.length - 1][1],
          points[0][0] - points[points.length - 1][0]
        ) *
          180) /
        Math.PI;

      var viewStateZoom;
      if (theViewState) {
        viewStateZoom = theViewState.zoom - 16;
      } else {
        viewStateZoom = 18; // this was causing an error as deck was null so i put this in as a guess
      }
      var addOn = Math.pow(20 - viewStateZoom, groundElevation > 400 ? 3 : 3);

      if (streetsData.length < (mobileAndTabletCheck() ? 5 : 10)) {
        if (index % divisor === 0) {
          streetsData.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                midPoint[0],
                midPoint[1],
                mobileAndTabletCheck() ? viewportElevation.elevation + 21 : 21,
              ],
            },
            properties: street.features[middle].tags,
            name: street.name,
            angle: angle,
          });
          streetsLineData.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [midPoint[0], midPoint[1], -30],
            },
            elevation: 30 + viewportElevation.elevation + 20,
            properties: street.features[middle].tags,
            name: street.name,
            angle: angle,
          });
        }
      }
    });

    streetsOnMap = streetsData;

    streetsLinesOnMap = streetsLineData;
    resolve(true);
  });
}

import * as React from "react";

import { useState, useImperativeHandle, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Map as PrintMap } from "react-map-gl";
import SurveyControls from "./SearchDetailComponents/SurveyControls";

import PropertyMap from "./Map";

import InfoCards from "./InfoCards";

import ClientModalSaveSurvey from "../Common/ClientProjectSurvey/ClientModalSaveSurvey";

import {
  Checkbox,
  Paper,
  Typography,
  checkboxClasses,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import TextOrCopySplash from "../Common/TextOrCopySplash";

import { FlyToInterpolator, Viewport } from "deck.gl";
import { grey } from "@mui/material/colors";
import { Button } from "@mui/material";
import axios from "axios";
import Fab from "@mui/material/Fab";

import NavigationIcon from "@mui/icons-material/Navigation";
import Share from "@mui/icons-material/Share";
import FormatLineSpacing from "@mui/icons-material/FormatLineSpacing";
import Map from "@mui/icons-material/Map";

import Box from "@mui/material/Box";

import { getViewportElevation } from "./ElevationFunctions/ElevationsFunctions";
import Dialog from "@mui/material/Dialog";
import { mobileAndTabletCheck } from "./Deck";

import ClientSurveyGrid from "../Common/ClientSurveyGrid";
import { styled } from "@mui/system";
import { useReactToPrint } from "react-to-print";
import { formatPhoneNumber } from "../Common/FormatPhoneNumber";
import { Marker } from "react-map-gl";
import { Tune } from "@mui/icons-material";

import { Menu as MenuIcon } from "@mui/icons-material";
import { CheckCircle, SaveAs as SaveAsIcon } from "@mui/icons-material";

import PrintSurvey from "../Common/PrintSurvey";
import PropertyGrid from "../Common/PropertyGrid";
import AvailableGrid from "../Common/AvailableGrid";
import { Alert } from "@mui/material";
import { getBuildingFromLatLon } from "./Deck";
// import { root } from "postcss";
import {
  // DensityMedium,
  Favorite,
  // Filter, Filter2Rounded, Fullscreen, Tune, UpdateDisabled,
} from "@mui/icons-material";
// import { map } from "d3-array";
import FilterDialogue from "../Common/FilterDialogue/FilterDialogue";
import FabRefresh from "./FabRefresh";
import { createCookie } from "../Common/Cookies";
import { AvailReportCard, CompReportCard } from "./InfoCard";
import SnackTour from "./SnackTour";

export const getGeoInfo = () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let coordinates = [
            position.coords.longitude,
            position.coords.latitude,
          ];

          var qs =
            process.env.REACT_APP_DATABASE +
            "api/getElevationLocal?latitude=" +
            (coordinates[1] + "&longitude=" + coordinates[0]);

          axios.get(qs).then(async (elevationData) => {
            var building = await getBuildingFromLatLon(
              coordinates[1],
              coordinates[0]
            ).catch((error) => {
              console.log(error);
            });

            var height = 5;

            if (building && building?.properties?.height) {
              if (!isNaN(building?.properties?.height)) {
                height = building?.properties?.height;
              }
            }
            var elevation = 0;
            if (elevationData?.data?.elevation) {
              if (!isNaN(elevationData?.data?.elevation)) {
                elevation = elevationData?.data?.elevation;
              }
            }

            var totalHeight = elevation + height;
            coordinates.push(totalHeight);

            resolve(coordinates);
          });
        },
        (error) => reject(error),
        { enableHighAccuracy: false, timeout: 20000, maximumAge: 1000 }
      );
    } else {
      // Geolocation is not supported by this browser
      alert("Geolocation is not supported by your browser.");
      reject({
        reason:
          "Computer, phone, browser, or app not enabled for location services.",
      });
    }
  });
};
const SearchDetail = React.forwardRef(
  (
    {
      geometry,
      user,
      searchResults,
      setSearchResults,
      setBoundsRef,
      setMinAvailSize,
      setMaxAvailSize,
      // navHeight,
      minAvailSize,
      maxAvailSize,
      clientName,
      projectName,
      surveyName,
      // setSurveyName,
      surveyNotes,
      // setSurveyNotes,
      dateMin,
      dateMax,
      setDateMin,
      setDateMax,
      reloadOnMoveIn,
      buildingPropertyType,
      setBuildingPropertyType,
      availPropertyType,
      setAvailPropertyType,
      bounds,
      addingNewProperties,
      setAddingNewProperties,
      availablesCheck,
      setAvailablesCheck,
      compsCheck,
      setCompsCheck,
      allCheck,
      setAllCheck,
      initialViewState,
      setInitialViewState,
      // filter,
      filters,
      setFilters,
      selectedLocation,
      setSelectedLocation,
    },
    ref
  ) => {
    let [query, setQuery] = useSearchParams();

    const [popupInfo, setPopupInfo] = useState(null);

    var refs = React.useRef([]);
    const [saveSurveyOpen, setSaveSurveyOpen] = useState(false);
    const propertyMapRef = React.useRef();
    const [selectedLocationId, setSelectedLocationId] = useState("");

    const [showFilters, setShowFilters] = useState(false);
    const [showWait, setShowWait] = useState(false);
    const [reloadOnMove, setReloadOnMove] = useState(
      reloadOnMoveIn === false ? false : true
    );
    const [elevationFactorState, setElevationFactorState] = useState(1.0);
    const [sliderElevation, setSliderElevation] = useState(1.0);
    const [showPrice, setShowPrice] = useState(false);

    const [confirm, setConfirm] = useState(false);
    const [mapButton, setMapButton] = useState(true);
    const [showBtnsMobile, setShowBtnsMobile] = useState(false);
    const [printSurvey, setPrintSurvey] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showTextOrCopy, setShowTextOrCopy] = useState(false);
    const [showListDialogue, setShowListDialogue] = useState(false);
    const [hoverInfo, setHoverInfo] = useState(null);
    const [currentViewState, setCurrentViewState] = useState(initialViewState);
    const [superMapRef, setSuperMapRef] = useState(false);
    const fabRefreshRef = useRef();
    const [showGrid, setShowGrid] = useState(false);
    const [showAvailableGrid, setShowAvailableGrid] = useState(false);
    const [showAvailablePrintableGrid, setShowAvailablePrintableGrid] =
      useState(false);
    const [showCompPrintableGrid, setShowCompPrintableGrid] = useState(false);
    const [toolTips, setTooltip] = useState(true);

    const [showParcels, setShowParcels] = useState(false);

    const [openTools, setOpenTools] = React.useState(false);
    const [showSurveys, setShowSurveys] = useState(false);

    const CustomTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      ".MuiTooltip-tooltip": {
        backgroundColor: "indigo", // Change this to your desired background color
      },
      ".MuiTooltip-arrow": {
        color: "indigo", // Change this to match the background color
      },
    });

    useEffect(() => {
      setTimeout(() => {
        if (toolTips) {
          setTooltip(false);
        }
      }, 10000);
    }, []);

    const handleClose = () => {
      setShowListDialogue(false);
    };

    useImperativeHandle(ref, () => ({
      mapReload(message) {
        mapReload(message);
      },
      setReloadOnMove() {
        setReloadOnMove(true);
      },
      setReloadOnMoveFalse() {
        setReloadOnMove(false);
      },

      refreshMapProperties() {
        refreshMapProperties();
      },
      setSearchedLocations(coordinates) {
        setSearchedLocations(coordinates);
      },

      getFilters() {
        return filters;
      },
      redoIcons() {
        propertyMapRef?.current?.redoIcons(selectedLocation);
      },
    }));

    function removeProperty(property) {
      propertyMapRef.current.removeProperty(property);
    }

    const refreshMapProperties = () => {
      propertyMapRef.current.refreshMapProperties();
    };
    const setSearchedLocations = (coordinates) => {
      propertyMapRef.current.setSearchedLocations(coordinates);
    };

    const moveMapToMe = async (contactId) => {
      setReloadOnMove(true);

      var coords = await getGeoInfo().catch((error) => {
        console.log(error);
      });

      if (!coords) {
        alert(
          "Please either enable your location, or just put in an address in the search bar.  Your location services are turned off for your browser."
        );
      }

      var myViewstate = {
        latitude: coords[1],
        longitude: coords[0],
        zoom: mobileAndTabletCheck() ? 16 : 14,
        // minZoom: mobileAndTabletCheck() ? 4 : 8,
        maxZoom: mobileAndTabletCheck() ? 19.5 : 19.5,
        bearing: 0,
        pitch: 41,
        transitionDuration: 1000,
        // transitionInterpolator: new FlyToInterpolator(),
        onTransitionEnd: mapReload,
      };

      var viewStateCookie = {
        ViewState: myViewstate,
        searchResults: null,
        filters: filters,
        URL: window.location.href,
      };

      var postResult = await axios
        .post(process.env.REACT_APP_DATABASE + "api/createViewStates", {
          userId: contactId,
          ViewState: viewStateCookie,
        })
        .catch((error) => {
          console.log(error);
        });

      var elevation = await getViewportElevation(
        null,
        searchResults && searchResults.length > 0 && searchResults[0]
      );
      if (elevation.elevation > 1000) {
        myViewstate.maxZoom = 13.5;
      } else if (elevation.elevation > 100) {
        myViewstate.maxZoom = 14;
      } else {
        myViewstate.maxZoom = 19.5;
      }

      setInitialViewState(myViewstate);
      setSearchedLocations([
        {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Point",
            coordinates: coords,
          },
        },
      ]); // we time this out so it might be last to load and cover up the rest
    };
    const showFiltersFunction = () => {
      setShowFilters(!showFilters);
      setShowBtnsMobile(false);
    };

    const shareThis = () => {
      if (surveyId) {
        existingSurvey();
      } else {
        newSurvey();
      }
    };

    function closeDialogue() {
      setShowFilters(false);
    }
    function filtersClose() {
      // setShowFilters(false)
      var newFilters = filters;
      newFilters.availablesCheck = availablesCheck;
      newFilters.compsCheck = compsCheck;
      newFilters.allCheck = allCheck;
      newFilters.availMin = minAvailSize;
      newFilters.availMax = maxAvailSize;

      newFilters.availPropertyType = availPropertyType;
      newFilters.buildingPropertyType = buildingPropertyType;
      newFilters.dateMax = dateMax;
      newFilters.dateMin = dateMin;
      newFilters.idArray = [];

      setFilters(newFilters);
      postFilters(
        newFilters,
        searchResults,
        initialViewState,
        user.contact.id,
        setFilters,
        setSearchResults,
        propertyMapRef
      );
    }

    const mapReload = (message) => {
      propertyMapRef.current.mapReload(message);
    };

    let squareFeet = query.get("squareFeet");
    let surveyId = query.get("surveyId");
    const [theSurveyId, setTheSurveyId] = useState(surveyId);
    let location = query.get("location");

    const handleLocationSelected = (result, setSearchResults) => {
      // scrollToResult(result);
      showMarkerPopup(result);
    };

    const handleLocationHover = (result) => {
      showMarkerPopup(result);
    };

    const showMarkerPopup = (result) => {
      setPopupInfo({ y: (window.innerHeight * 2) / 3, object: result });

      setSelectedLocation(result);
    };

    const showPropertyModal = (id) => {
      window.open("property?id=" + id);
    };

    var minSizeDisplay = "";
    if (squareFeet & (squareFeet !== "0")) {
      minSizeDisplay =
        " | " +
        squareFeet.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " min square feet available";
    }

    const existingSurvey = async () => {
      //test - console.log("existing survey");
      setShowLoading(true);

      try {
        var result = await saveOverSurveyResultsPromise(
          surveyId,
          searchResults,
          minAvailSize,
          maxAvailSize,
          dateMin,
          dateMax,

          buildingPropertyType,
          availPropertyType,
          surveyName,
          surveyNotes,
          user.contact.id,
          compsCheck,
          allCheck,
          availablesCheck
        );

        await axios.get(
          process.env.REACT_APP_DATABASE + "api/metaSurvey?id=" + surveyId
        );

        if (mobileAndTabletCheck() <= 700) {
          setShowTextOrCopy(true);
        } else {
          navigator.clipboard.writeText(
            "https://images.cubicsearch.com/meta/surveys/" + surveyId + ".html"
          );
          alert(
            "Survey has been saved and link has been copied to the clipboard! \nhttps://images.cubicsearch.com/meta/surveys/" +
              surveyId +
              ".html"
          );
        }
      } catch (error) {
        alert(JSON.stringify(error));
      }
    };
    var privateBuildings = [];
    var privateAvails = [];

    const newSurvey = async () => {
      searchResults.map((searchResult) => {
        var buildingPublic = false;
        buildingPublic = searchResult.groups.find((group) => {
          return group.building_group.groupId === 1;
        });
        if (!buildingPublic) {
          privateBuildings.push(searchResult);
        }

        searchResult.avails.map((avail) => {
          if (avail.public === 0) {
            privateAvails.push(searchResult);
          }
        });
      });

      if (privateBuildings.length === 0 && privateAvails.length === 0) {
        setShowLoading(true);
        try {
          var surveyId = await axios.get(
            process.env.REACT_APP_DATABASE +
              "api/addPublicSurvey?contactId=" +
              user.contact.id +
              "&availMin=" +
              encodeURIComponent(minAvailSize) +
              "&availMax=" +
              encodeURIComponent(maxAvailSize) +
              "&dateMin=" +
              encodeURIComponent(dateMin) +
              "&dateMax=" +
              encodeURIComponent(dateMax) +
              "&buildingPropertyType=" +
              encodeURIComponent(buildingPropertyType) +
              "&availPropertyType=" +
              encodeURIComponent(availPropertyType)
          );

          var thesurvey = surveyId.data;
          var newSurveyId = thesurvey.id;
          var surveyResultsFromFrontEnd = searchResults.map(
            ({ id }, index) => ({
              buildingId: id,
              sortOrder: index,
              surveyId: newSurveyId,
            })
          ); // This extracts just the ids of the buildings

          var fetchString =
            process.env.REACT_APP_DATABASE + "api/createSurveyResults";
          var response = axios.post(fetchString, {
            contactId: user.contact.id,
            surveyResultsFromFrontEnd: surveyResultsFromFrontEnd,
          });

          fetchString =
            process.env.REACT_APP_DATABASE +
            "api/metaSurvey?id=" +
            thesurvey.id;
          await axios.get(fetchString);
          //test - console.log("finished : " + fetchString);

          setShowLoading(false);
          //test - console.log("setting survey ID");
          setTheSurveyId(thesurvey.id);
          //test - console.log("setting showLoading");
          if (mobileAndTabletCheck() <= 700) {
            setShowTextOrCopy(true);
          } else {
            navigator.clipboard.writeText(
              "https://images.cubicsearch.com/meta/surveys/" +
                thesurvey.id +
                ".html"
            );
            alert(
              "Survey has been saved and link has been copied to the clipboard! \nhttps://images.cubicsearch.com/meta/surveys/" +
                thesurvey.id +
                ".html"
            );
          }
        } catch (error) {
          alert(JSON.stringify(error));
        }
      } else {
        alert(
          privateBuildings.length > 0
            ? privateBuildings.length +
                " of the properties and " +
                privateAvails.length +
                " of the availables and transactions are not public.  You must save the results as a survey."
            : privateAvails.length +
                " of the availables and transactions are not public.  You must save the results as a survey."
        );

        if (user.Email.indexOf("@") > 0) {
          // setShowLoading(false);
          setSaveSurveyOpen(true);
        }
      }
    };

    var filterInputs = {
      postFilters: postFilters,
      filtersClose: filtersClose,
      availablesCheck: availablesCheck,
      setAvailablesCheck: setAvailablesCheck,
      filters: filters,
      setFilters: setFilters,
      compsCheck: compsCheck,
      setCompsCheck: setCompsCheck,
      allCheck: allCheck,
      setAllCheck: setAllCheck,
      searchResults: searchResults,
      setSearchResults: setSearchResults,
      maxAvailSize: maxAvailSize,
      setMaxAvailSize: setMaxAvailSize,
      minAvailSize: minAvailSize,
      setMinAvailSize: setMinAvailSize,

      availPropertyType: availPropertyType,
      setAvailPropertyType: setAvailPropertyType,
      buildingPropertyType: buildingPropertyType,
      setBuildingPropertyType: setBuildingPropertyType,
      location: location,
      initialViewState: initialViewState,
      user: user,
      propertyMapRef: propertyMapRef,
      showFilters: showFilters,
      dateMin: dateMin,
      setDateMin: setDateMin,
      dateMax: dateMax,
      setDateMax: setDateMax,
    };

    return (
      <Box
        sx={{
   
    
            height: '100%'
        }}
      >
        <SurveyControls
        user={user}
        saveSurveyOpen={saveSurveyOpen}
        setSaveSurveyOpen={setSaveSurveyOpen}
        searchResults={searchResults}
        minAvailSize={minAvailSize}
        maxAvailSize={maxAvailSize}
        dateMin={dateMin}
        dateMax={dateMax}
        buildingPropertyType={buildingPropertyType}
        availPropertyType={availPropertyType}
        availablesCheck={availablesCheck}
        setAvailablesCheck={setAvailablesCheck}
        compsCheck={compsCheck}
        setCompsCheck={setCompsCheck}
        allCheck={allCheck}
        setAllCheck={setAllCheck}
        mobileAndTabletCheck={mobileAndTabletCheck}
        surveyId={surveyId}
        setShowListDialogue={setShowListDialogue}
        setConfirm={setConfirm}
        confirm={confirm}
        filters={filters}
        setFilters={setFilters}
        postFilters={postFilters}
        initialViewState={initialViewState}
        propertyMapRef={propertyMapRef}
        showParcels={showParcels}
        currentViewState={currentViewState}
        setShowParcels={setShowParcels}
        printSurvey={printSurvey}
        setPrintSurvey={setPrintSurvey}
        bounds={bounds}
        refs={refs}
        mapButton={mapButton}
        selectedLocation={selectedLocation}
        showMarkerPopup={showMarkerPopup}
        showPropertyModal={showPropertyModal}
        showPrice={showPrice}
        surveyName={surveyName}
        setSearchResults = {setSearchResults}
        setMaxAvailSize = {setMaxAvailSize}
        setMinAvailSize = {setMinAvailSize}
        setAvailPropertyType = {setAvailPropertyType}
        setBuildingPropertyType = {setBuildingPropertyType} 
        surveyNotes={surveyNotes}
        filtersClose={filtersClose}
        closeDialogue={closeDialogue}
        showFilters={showFilters}
        setDateMin={setDateMin}
        setDateMax={setDateMax}
        location={location}
      /> 
  

        {showLoading ? (
          <>
            <Dialog
              open={showLoading}
              onClose={() => {
                setShowLoading(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{ marginTop: 5, marginLeft: 15, marginRight: 15 }}
                />

                <Typography
                  sx={{ marginBottom: 5, marginLeft: 15, marginRight: 15 }}
                >
                  Creating share link...
                </Typography>
              </div>
            </Dialog>
          </>
        ) : (
          <></>
        )}


        <SnackTour
          surveyId={surveyId}
          clientName={clientName}
          projectName={projectName}
          getGeoInfo={getGeoInfo}
        />

        <Box
          sx={{
      
            height: '100%'
  
          }}
        >
        
           {mobileAndTabletCheck() ? (
            <>
              <Dialog
                open={!mapButton}
                style={{ top: 88 }}
                onClose={() => {
                  setMapButton(true);
                }}
                fullScreen={true}
              >
                <div
                  // ref={sidebarRef}
                  style={{
                    width: "100%",
                    flexGrow: 0,
                    flexShrink: 0,
                    minWidth: "0px",
                    // maxWidth: '600px',
                    display: "flex",
                    borderRight: 1,
                    flexDirection: "row",
                    background: "#ffffff",
                    boxShadow: "-8px 2px 22px -7px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px 0px 0px 10px",
                    // visibility: mobileAndTabletCheck()? 'hidden': 'visible',
                    zIndex: 2,
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  className={
                    mapButton && window.innerWidth < 768 ? "hidden" : ""
                  }
                >
                  <div style={{ position: "relative" }}>
                    <div
                      style={{ position: "fixed", zIndex: 2000, width: "100%" }}
                    >
                      <Box
                        elevation={0}
                        sx={{
                          position: "fixed",
                          top: window.innerHeight - 100,
                          width: window.innerWidth,
                          paddingRight: 10,
                          paddingLeft: 10,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <>
                          {mobileAndTabletCheck() ? (
                            <div className="py-1 ">
                              <Tooltip
                                disableFocusListener
                                open={toolTips}
                                title="Property List or Map"
                                placement="left"
                              >
                                <Fab
                                  sx={{
                                    backgroundColor: "white",
                                    color: "indigo",
                                  }}
                                  onClick={() => {
                                    // MapInfoCardsFlip()

                                    setMapButton(!mapButton);
                                  }}
                                >
                                  {mapButton ? <FormatLineSpacing /> : <Map />}
                                </Fab>
                              </Tooltip>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="py-1">
                            <Tooltip
                              open={!showFilters && toolTips}
                              onMouseOver={() => {
                                setTooltip(true);
                                setTimeout(() => {
                                  setTooltip(false);
                                }, 3000);
                              }}
                              disableFocusListener
                              title="Save & Share Survey"
                              placement="left"
                            >
                              <Fab
                                sx={{
                                  backgroundColor: "white",
                                  color: "indigo",
                                }}
                                onClick={() => {
                                  shareThis();
                                }}
                              >
                                <Share />
                              </Fab>
                            </Tooltip>
                          </div>

                          <div className="py-1">
                            <Tooltip
                              open={!showFilters && toolTips}
                              onMouseOver={() => {
                                setTooltip(true);
                                setTimeout(() => {
                                  setTooltip(false);
                                }, 3000);
                              }}
                              disableFocusListener
                              title="Filters and More"
                              placement="left"
                            >
                              <Fab
                                sx={{
                                  backgroundColor: "white",
                                  color: "indigo",
                                }}
                                onClick={() => {
                                  setShowFilters(false);
                                  showFiltersFunction();
                                }}
                              >
                                <Tune />
                              </Fab>
                            </Tooltip>
                          </div>
                        </>
                      </Box>
                      <div>
                        <div
                          // ref={sidebarRef}
                          style={{
                            width: "100%",
                            flexGrow: 0,
                            flexShrink: 0,
                            minWidth: "0px",
                            // maxWidth: '600px',
                            display: "flex",
                            borderRight: 1,
                            flexDirection: "row",
                            background: "#ffffff",
                            boxShadow: "-8px 2px 22px -7px rgba(0, 0, 0, 0.25)",
                            borderRadius: "10px 0px 0px 10px",
                            // visibility: mobileAndTabletCheck()? 'hidden': 'visible',
                            zIndex: 2,
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          className={
                            mapButton && window.innerWidth < 768 ? "hidden" : ""
                          }
                        ></div>
                      </div>
                    </div>

                    <div
                      className={"flex flex-col"}
                      style={{
                        maxWidth: window.innerWidth,
                        marginTop: 70,
                        resize: true,
                        overflowY: "scroll",
                      }}
                    >
                      <div>
                        <div style={{}}>
                          <div
                            // ref={sidebarRef}
                            style={{
                              width: "100%",
                              flexGrow: 0,
                              flexShrink: 0,
                              minWidth: "0px",
                              // maxWidth: '600px',
                              display: "flex",
                              borderRight: 1,
                              flexDirection: "row",
                              background: "#ffffff",
                              boxShadow:
                                "-8px 2px 22px -7px rgba(0, 0, 0, 0.25)",
                              borderRadius: "10px 0px 0px 10px",
                              // visibility: mobileAndTabletCheck()? 'hidden': 'visible',
                              zIndex: 2,
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            className={
                              mapButton && window.innerWidth < 768
                                ? "hidden"
                                : ""
                            }
                          ></div>
                        </div>
                        <div id={"somecards"}>
                          <InfoCards
                            removeProperty={removeProperty}
                            filterInputs={filterInputs}
                            setMapButton={setMapButton}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            setSearchedLocations={setSearchedLocations}
                            refs={refs}
                            onHover={showMarkerPopup}
                            showSurveys={showSurveys}
                            setShowSurveys={setShowSurveys}
                            setSaveSurveyOpen={setSaveSurveyOpen}
                            onClick={async (info) => {
                              var theProperty = searchResults.find(
                                (theresult) => {
                                  if (theresult.id === info) {
                                    return true;
                                  }
                                }
                              );
                              //test - console.log(theProperty)
                              setSearchedLocations([
                                {
                                  type: "Feature",
                                  properties: {},
                                  geometry: {
                                    type: "Point",
                                    coordinates: [
                                      theProperty.Longitude,
                                      theProperty.Latitude,
                                      theProperty.Elevation,
                                    ],
                                  },
                                },
                              ]);

                              setReloadOnMove(false);
                              setPopupInfo({
                                y: window.innerHeight / 3,
                                object: theProperty,
                              });
                              setSearchResults(searchResults);

                              var myViewstate = {
                                latitude: theProperty.Latitude,
                                longitude: theProperty.Longitude,
                                zoom: mobileAndTabletCheck() ? 16 : 14,
                                // minZoom: mobileAndTabletCheck() ? 4 : 8,
                                maxZoom: mobileAndTabletCheck() ? 19.5 : 19.5,
                                bearing: 0,
                                pitch: 41,
                                transitionDuration: 0,
                                transitionInterpolator: new FlyToInterpolator(),
                              };

                              var elevation = await getViewportElevation(
                                null,
                                searchResults &&
                                  searchResults.length > 0 &&
                                  searchResults[0]
                              );
                              if (elevation.elevation > 1000) {
                                myViewstate.maxZoom = 13.5;
                              } else if (elevation.elevation > 100) {
                                myViewstate.maxZoom = 14;
                              } else {
                                myViewstate.maxZoom = 19.5;
                              }

                              setInitialViewState(myViewstate);

                              propertyMapRef.current.flyTo(
                                {
                                  latitude: theProperty.Latitude,
                                  longitude: theProperty.Longitude,
                                },
                                theProperty.BuildingHeight
                              );

                              refreshMapProperties();
                            }}
                            showMe={!mapButton}
                            remove={true}
                            confirm={confirm}
                            contactId={user.contact.id}
                            surveyId={surveyId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog>
            </>
          ) : (
            <></>
          )}
 

           <Box
            sx={{
        
                 height: '100%'
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: mobileAndTabletCheck() ? 10 : 80, // Decreased from 20 and 100 respectively to move buttons up
                right: mobileAndTabletCheck() ? 20 : 100, // Right position remains the same
                zIndex: 1200,
                display: mapButton && !showFilters ? "block" : "none",
              }}
            >
              <>
                {mobileAndTabletCheck() ? (
                  <div className="py-1 ">
                    <Tooltip
                      disableFocusListener
                      open={!showFilters && toolTips}
                      title="Property List or Map"
                      placement="left"
                    >
                      <Fab
                        sx={{ backgroundColor: "white", color: "indigo" }}
                        onClick={() => {
                          setMapButton(!mapButton);
                        }}
                      >
                        {mapButton ? <FormatLineSpacing /> : <Map />}
                      </Fab>
                    </Tooltip>
                  </div>
                ) : (
                  <></>
                )}
                <div className="py-1">
                  <Tooltip
                    open={!showFilters && toolTips}
                    onMouseOver={() => {
                      setTooltip(true);
                      setTimeout(() => {
                        setTooltip(false);
                      }, 3000);
                    }}
                    disableFocusListener
                    title="Save & Share Survey"
                    placement="left"
                  >
                    <Fab
                      sx={{ backgroundColor: "white", color: "indigo" }}
                      onClick={() => {
                        shareThis();
                      }}
                    >
                      <Share />
                    </Fab>
                  </Tooltip>
                </div>

                <div className="py-1">
                  <Tooltip
                    open={!showFilters && toolTips}
                    onMouseOver={() => {
                      setTooltip(true);
                      setTimeout(() => {
                        setTooltip(false);
                      }, 3000);
                    }}
                    disableFocusListener
                    title="Filters and More"
                    placement="left"
                  >
                    <Fab
                      sx={{ backgroundColor: "white", color: "indigo" }}
                      onClick={() => {
                        showFiltersFunction();
                      }}
                    >
                      <Tune />
                    </Fab>
                  </Tooltip>
                </div>
                <div className="py-1">
                  <Tooltip
                    open={!showFilters && toolTips}
                    onMouseOver={() => {
                      setTooltip(true);
                      setTimeout(() => {
                        setTooltip(false);
                      }, 3000);
                    }}
                    title="Find Me"
                    placement="left"
                  >
                    <Fab
                      sx={{ backgroundColor: "white", color: "indigo" }}
                      onClick={async () => {
                        if (!mapButton) {
                          setMapButton(true);
                        }

                        setShowWait(true);

                        await moveMapToMe(user.contact.id);
                        setShowWait(false);
                      }}
                    >
                      <NavigationIcon />
                    </Fab>
                  </Tooltip>
                </div>

                <CustomTooltip
                  arrow
                  open={!reloadOnMove}
                  disableFocusListener
                  title="To refresh properties on moving map, click this button"
                  placement="left"
                >
                  <div className="py-1 pb-4">
                    <FabRefresh
                      toolTips={!showFilters && toolTips}
                      reloadOnMove={reloadOnMove}
                      setReloadOnMove={setReloadOnMove}
                      ref={fabRefreshRef}
                      setSuperMapRef={setSuperMapRef}
                    />
                  </div>
                </CustomTooltip>
              </>
            </div>

            <Box
            sx={{
              height: '100%',
              width: '100%'
            }} id="mapDiv">
              <PropertyMap
                fabRefreshRef={fabRefreshRef}
                filterInputs={filterInputs}
                ref={propertyMapRef}
                superMapRef={superMapRef}
                setSuperMapRef={setSuperMapRef}
                geometry={geometry}
                filters={filters}
                setFilters={setFilters}
                key={99}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                bounds={bounds}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                onMarkerClick={handleLocationSelected}
                onMarkerHover={handleLocationHover}
                popupInfo={popupInfo}
                setPopupInfo={setPopupInfo}
                hoverInfo={hoverInfo}
                setHoverInfo={setHoverInfo}
                // setID={setSelectedLocationId}
                refs={refs}
                // setRefs={setRefs}
                contactId={user?.contact.id}
                setBoundsRef={setBoundsRef}
                reloadOnMove={reloadOnMove}
                elevationFactorState={elevationFactorState}
                setElevationFactorState={setElevationFactorState}
                setReloadOnMove={setReloadOnMove}
                showParcels={showParcels}
                setAddingNewProperties={setAddingNewProperties}
                addingNewProperties={addingNewProperties}
                showMap={true}
                showPrice={showPrice}
                initialViewState={initialViewState}
                setInitialViewState={setInitialViewState}
                confirmIn={confirm}
                mapButton={mapButton}
                setShowWait={setShowWait}
                surveyId={surveyId}
                setCurrentViewState={setCurrentViewState}
                setShowSurveys={setShowSurveys}
                setSaveSurveyOpen={setSaveSurveyOpen}
                // showAlert={showAlert}
                // setShowAlert={setShowAlert}
              />
            </Box>
          </Box>  
        </Box>

        {showTextOrCopy ? (
          <>
            <TextOrCopySplash
              open={showTextOrCopy}
              setOpen={setShowTextOrCopy}
              website={"https://images.cubicsearch.com/meta/surveys/"}
              id={theSurveyId}
            />
          </>
        ) : (
          <></>
        )}
        {/* <div style={{height: 100,backgroundColor: 'gray', color:'white'}} className="hidden-mobile"><Footer /></div> */}

        <PropertyGrid
          dataIn={searchResults}
          open={showGrid}
          setOpen={setShowGrid}
        />
        <AvailableGrid
          dataIn={searchResults}
          open={showAvailableGrid}
          setOpen={setShowAvailableGrid}
        />
        <AvailReportDialogue
          buildings={searchResults}
          showListDialogue={showAvailablePrintableGrid}
          handleClose={setShowAvailablePrintableGrid}
          user={user}
          bounds={bounds}
          surveyId={surveyId}
        />
        <CompReportDialogue
          buildings={searchResults}
          showListDialogue={showCompPrintableGrid}
          handleClose={setShowCompPrintableGrid}
          user={user}
          bounds={bounds}
          surveyId={surveyId}
          filters={filters}
        />
        <ClientSurveyGrid
          open={showSurveys}
          setOpen={setShowSurveys}
          contactId={user.contact.id}
          propertyId={selectedLocation?.id}
        />
        <Dialog open={showListDialogue} onClose={handleClose}>
          <DialogTitle>{"Reports"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              component={"div"}
              id="alert-dialog-slide-description"
            >
              Choose a report to download or print.
              <div style={{ paddingTop: 20 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowGrid(true);
                    handleClose();
                  }}
                >
                  Building List Report
                </Button>
              </div>
              <div style={{ paddingTop: 10 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowAvailableGrid(true);
                    handleClose();
                  }}
                >
                  Available List Data Grid
                </Button>
              </div>
              <div style={{ paddingTop: 10 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowAvailablePrintableGrid(true);
                    handleClose();
                  }}
                >
                  Available List Printable Report
                </Button>
              </div>
              <div style={{ paddingTop: 10 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowCompPrintableGrid(true);
                    handleClose();
                  }}
                >
                  Comparable Lease Transaction Printable Report
                </Button>
              </div>
              {/* <div style={{ paddingTop: 10 }}>
                <Button variant="outlined"
                  onClick={() => {

                    setPrintSurvey(true);
                    handleClose()
                  }}
                >

                  PDF Brochure Report With Links
                </Button>
              </div> */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export default SearchDetail;
function toTitleCase(str) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function CompReportDialogue({
  buildings,
  showListDialogue,
  handleClose,
  user,
  bounds,
  surveyId,
  filters,
}) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!showListDialogue) {
    return <></>;
  }
  var newBuildings = [];
  for (var building of buildings) {
    building.avails = building.avails
      .filter((avail) => {
        if (filters.dateMin && !filters.dateMax) {
          if (new Date(avail.ExecutionDate) > new Date(filters.dateMin)) {
            return avail.Status === "Lease Comp";
          }
        } else if (!filters.dateMin && filters.dateMax) {
          if (new Date(avail.ExecutionDate) < new Date(filters.dateMax)) {
            return avail.Status === "Lease Comp";
          }
        } else if (filters.dateMin && filters.dateMax) {
          if (
            new Date(avail.ExecutionDate) < new Date(filters.dateMax) &&
            new Date(avail.ExecutionDate) > new Date(filters.dateMin)
          ) {
            return avail.Status === "Lease Comp";
          }
        } else {
          return avail.Status === "Lease Comp";
        }
      })
      .sort((a, b) => {
        return new Date(b.ExecutionDate) - new Date(a.ExecutionDate);
      });

    newBuildings.push(building);
  }

  buildings = newBuildings.filter((building) => {
    if (building.avails.length > 0) {
      return true;
    } else {
      return false;
    }
  });

  buildings.sort((a, b) => {
    {
      return (
        new Date(b.avails[0].ExecutionDate) -
        new Date(a.avails[0].ExecutionDate)
      );
    }
  });

  var pins = // useMemo used to be here for some reason.    Took it out so the dots would go away.
    buildings.map((result, index) => (
      <Marker
        key={result.id}
        longitude={result.Longitude}
        latitude={result.Latitude}
        anchor="bottom"
        onClick={(e) => {
          // If we let the click event propagates to the map, it will immediately close the popup
          // with `closeOnClick: true`
          // onLocationChanged(result);
          e.originalEvent.stopPropagation();
          // onMarkerClick(result);
        }}
      >
        <div className="flex justify-end w-full">
          <div className="flex items-center border-2 justify-center text-white rounded-full bg-red-600 w-6 h-6">
            {index + 1}{" "}
          </div>
        </div>
      </Marker>
    ));

  const uniqueCitiesPrep = [
    ...new Set(buildings.map((item) => item?.City?.toLowerCase())),
  ];
  const uniqueCities = uniqueCitiesPrep
    ?.map((name) => name && toTitleCase(name))
    .filter((name) => name?.trim() !== "")
    .join(",")
    .slice(0, 40);
  const uniqueTypePrep = [
    ...new Set(buildings.map((item) => item?.PropertyType?.toLowerCase())),
  ];
  const uniqueType = uniqueTypePrep
    ?.map((name) => name && toTitleCase(name))
    .filter((name) => name?.trim() !== "")
    .join(",")
    .slice(0, 40);

  return (
    <>
      <Dialog
        open={showListDialogue}
        maxWidth={"xl"}
        onClose={() => {
          handleClose(false);
        }}
      >
        <style>
          {`
          @media print {
            .custom-header {
              /* Add styles for the custom header when printing */
              font-size: 20px;
              font-weight: bold;
              break-inside: avoid;
            }
          }

          .MuiDialogActions-root {
            /* Add styles for the DialogActions */
            break-inside: avoid;
            
          }
        `}
        </style>
        {/* <DialogTitle>   Available Report</DialogTitle> */}
        <DialogContent>
          <DialogContentText
            ref={componentRef}
            component={"div"}
            id="alert-dialog-slide-description"
          >
            <div
              style={{ marginLeft: 40, paddingTop: 80 }}
              className="custom-header"
            >
              <Typography variant="h4">
                Lease Transaction Property Report
              </Typography>
              <Typography variant="h6">
                {uniqueCities} | {uniqueType}
              </Typography>
              <Typography variant="h6">
                {new Date().toLocaleDateString()}
              </Typography>

              <div className="flex flex-col items-start">
                <img
                  className="flex flex-col h-10 w-20 object-contain items-start"
                  src={
                    user.contact.office && user.contact.office.company
                      ? user.contact.office.company.images[0].img
                      : "https://images.cubicsearch.com/CompanyLogo.png"
                  }
                  alt=""
                />
                <Typography>
                  {user.contact.FirstName + " " + user.contact.LastName}
                </Typography>
                <Typography>{user.contact.Email}</Typography>
                <Typography>
                  {" "}
                  {formatPhoneNumber(user.contact.Phone)}{" "}
                </Typography>
                <Paper
                  elevation={6}
                  sx={{
                    marginRight: 10,
                    marginTop: 10,
                    padding: 5,
                    borderRadius: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 600,
                    width: 600,
                  }}
                >
                  <PrintMap
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
                    initialViewState={
                      bounds
                        ? {
                            bounds: [
                              {
                                lat: bounds.minLat,
                                lng: bounds.minLng,
                              },
                              {
                                lat: bounds.maxLat,
                                lng: bounds.maxLng,
                              },
                            ],
                            fitBoundsOptions: {
                              padding: 50,
                              maxZoom: 15,
                            },
                          }
                        : {
                            bounds: [
                              { lat: 37.562937, lng: -122.433014 },
                              { lat: 37.662937, lng: -122.300014 },
                            ],
                            fitBoundsOptions: {
                              padding: 50,
                              maxZoom: 12,
                            },
                          }
                    }
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                    preserveDrawingBuffer={true}
                  >
                    {pins}
                  </PrintMap>
                </Paper>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* {surveyId ?
              <a href={window.location.origin + "/search?surveyId=" + surveyId} target="_blank">
                <Button variant="contained">{"Interactive Survey"}</Button></a> : <></>} */}
            </div>
            {buildings.map((building, index) => {
              return (
                <Box key={"dialogue" + index} sx={{ width: "100%" }}>
                  <CompReportCard
                    key={"CompCard" + index}
                    building={building}
                    index={index}
                  />
                </Box>
              );
            })}

            <footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography>
                  {user.contact.FirstName + " " + user.contact.LastName}
                </Typography>
                <Typography sx={{ marginLeft: 1 }}>
                  {" | " + user.contact.Email}
                </Typography>
                <Typography sx={{ marginLeft: 1 }}>
                  {" "}
                  {" | " + formatPhoneNumber(user.contact.Phone)}{" "}
                </Typography>
              </div>
            </footer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(false);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button variant="contained" onClick={handlePrint}>
            Print to PDF
          </Button>
          {/* <Button variant="contained" onClick={handlePrint}>
          Share a Link
        </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export function AvailReportDialogue({
  buildings,
  showListDialogue,
  handleClose,
  user,
  bounds,
  surveyId,
}) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var pins = // useMemo used to be here for some reason.    Took it out so the dots would go away.
    buildings.map((result, index) => (
      <Marker
        key={result.id}
        longitude={result.Longitude}
        latitude={result.Latitude}
        anchor="bottom"
        onClick={(e) => {
          // If we let the click event propagates to the map, it will immediately close the popup
          // with `closeOnClick: true`
          // onLocationChanged(result);
          e.originalEvent.stopPropagation();
          // onMarkerClick(result);
        }}
      >
        <div className="flex justify-end w-full">
          <div className="flex items-center border-2 justify-center text-white rounded-full bg-red-600 w-6 h-6">
            {index + 1}{" "}
          </div>
        </div>
      </Marker>
    ));

  const uniqueCitiesPrep = [
    ...new Set(buildings.map((item) => item?.City?.toLowerCase())),
  ];
  const uniqueCities = uniqueCitiesPrep
    ?.map((name) => name && toTitleCase(name))
    .filter((name) => name?.trim() !== "")
    .join(",")
    .slice(0, 40);
  const uniqueTypePrep = [
    ...new Set(buildings.map((item) => item?.PropertyType?.toLowerCase())),
  ];
  const uniqueType = uniqueTypePrep
    ?.map((name) => name && toTitleCase(name))
    .filter((name) => name?.trim() !== "")
    .join(",")
    .slice(0, 40);

  return (
    <div>
      <Dialog
        open={showListDialogue}
        maxWidth={"xl"}
        onClose={() => {
          handleClose(false);
        }}
      >
        <style>
          {`
          @media print {
            .custom-header {
              /* Add styles for the custom header when printing */
              font-size: 20px;
              font-weight: bold;
              break-inside: avoid;
            }
          }

          .MuiDialogActions-root {
            /* Add styles for the DialogActions */
            break-inside: avoid;
            
          }
        `}
        </style>
        {/* <DialogTitle>   Available Report</DialogTitle> */}
        <DialogContent>
          <DialogContentText
            ref={componentRef}
            component={"div"}
            id="alert-dialog-slide-description"
          >
            <div
              style={{ marginLeft: 40, paddingTop: 80 }}
              className="custom-header"
            >
              <Typography variant="h4">Available Property Report</Typography>
              <Typography variant="h6">
                {uniqueCities} | {uniqueType}
              </Typography>
              <Typography variant="h6">
                {new Date().toLocaleDateString()}
              </Typography>

              <div className="flex flex-col items-start">
                <img
                  className="flex flex-col h-10 w-20 object-contain items-start"
                  src={
                    user.contact.office && user.contact.office.company
                      ? user.contact.office.company.images[0].img
                      : "https://images.cubicsearch.com/CompanyLogo.png"
                  }
                  alt=""
                />
                <Typography>
                  {user.contact.FirstName + " " + user.contact.LastName}
                </Typography>
                <Typography>{user.contact.Email}</Typography>
                <Typography>
                  {" "}
                  {formatPhoneNumber(user.contact.Phone)}{" "}
                </Typography>
              </div>
            </div>

            <div
              style={{
                marginBottom: 100,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {surveyId ? (
                <a
                  href={window.location.origin + "/search?surveyId=" + surveyId}
                  target="_blank"
                >
                  <Button variant="contained">{"Interactive Survey"}</Button>
                </a>
              ) : (
                <></>
              )}

              <Paper
                elevation={6}
                className="PrintMap"
                sx={{
                  margin: 20,
                  padding: 5,
                  borderRadius: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: 600,
                  width: 800,
                }}
              >
                <PrintMap
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  mapStyle="mapbox://styles/schordr/ckvq4te666xrm14lq6wmj1fi0"
                  initialViewState={
                    bounds
                      ? {
                          bounds: [
                            {
                              lat: bounds.minLat,
                              lng: bounds.minLng,
                            },
                            {
                              lat: bounds.maxLat,
                              lng: bounds.maxLng,
                            },
                          ],
                          fitBoundsOptions: {
                            padding: 50,
                            maxZoom: 15,
                          },
                        }
                      : {
                          bounds: [
                            { lat: 37.562937, lng: -122.433014 },
                            { lat: 37.662937, lng: -122.300014 },
                          ],
                          fitBoundsOptions: {
                            padding: 50,
                            maxZoom: 12,
                          },
                        }
                  }
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                  preserveDrawingBuffer={true}
                >
                  {pins}
                </PrintMap>
              </Paper>
            </div>

            <div>
              {buildings.map((building, index) => {
                return (
                  <Box key={"dialogue" + index} sx={{ width: "100%" }}>
                    <AvailReportCard
                      key={"AvailCard" + index}
                      building={building}
                      index={index}
                    />
                  </Box>
                );
              })}
            </div>

            <footer>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography>
                  {user.contact.FirstName + " " + user.contact.LastName}
                </Typography>
                <Typography sx={{ marginLeft: 1 }}>
                  {" | " + user.contact.Email}
                </Typography>
                <Typography sx={{ marginLeft: 1 }}>
                  {" "}
                  {" | " + formatPhoneNumber(user.contact.Phone)}{" "}
                </Typography>
              </div>
            </footer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(false);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button variant="contained" onClick={handlePrint}>
            Print to PDF
          </Button>
          {/* <Button variant="contained" onClick={handlePrint}>
          Share a Link
        </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function formatProdPrice(value) {
  if (isNaN(value) || value === 0 || !value) {
    return "Please Inquire for Price";
  } else {
    return (
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value) + " / SF"
    );
  }
}

function saveOverSurveyResultsPromise(
  surveyId,
  searchResults,
  availMin,
  availMax,
  dateMin,
  dateMax,
  buildingPropertyType,
  availPropertyType,
  surveyName,
  surveyNotes,
  contactId,
  compsCheck,
  allCheck,
  availablesCheck
) {
  return new Promise(function (resolve, reject) {
    var filters = {
      SurveyName: surveyName,
      Notes: surveyNotes,
      availMin: availMin,
      availMax: availMax,
      dateMin: dateMin,
      dateMax: dateMax,
      buildingPropertyType: buildingPropertyType,
      availPropertyType: availPropertyType,
      compsCheck: compsCheck,
      allCheck: allCheck,
      availablesCheck: availablesCheck,
    };
    var surveyResultsFromFrontEnd = searchResults.map(({ id }, index) => ({
      buildingId: id,
      sortOrder: index,
      surveyId: surveyId,
    })); // This extracts just the ids of the buildings
    var sendingPrep = encodeURI(
      window.btoa(JSON.stringify(surveyResultsFromFrontEnd))
    );

    fetch(
      process.env.REACT_APP_DATABASE +
        "api/SaveOverSurveyResults?surveyResultsFromFrontEnd=" +
        sendingPrep +
        "&id=" +
        surveyId +
        "&contactId=" +
        contactId
    )
      // .then((response) => response.json())
      .then((data) => {
        fetch(
          process.env.REACT_APP_DATABASE +
            "api/updateOpenSurvey?filters=" +
            encodeURIComponent(JSON.stringify(filters)) +
            "&id=" +
            surveyId
        )
          .then((fetchResults) => {
            alert("Survey Saved");
            //test - console.log("Made it")
            resolve("Survey Saved");
          })
          .catch((err) => {
            reject("Survey Failed");
          });
      });
  });
}

async function postFilters(
  filters,
  searchResults,
  initialViewState,
  contactId,
  setFilters,
  setSearchResults,
  propertyMapRef
) {
  setFilters(filters);
  var viewStateCookie = {
    ViewState: initialViewState,
    searchResults: searchResults,
    filters: filters,
    URL: window.location.href,
  };

  //test - console.log("IS THIS GETTING IN?")

  var postResult = await axios
    .post(process.env.REACT_APP_DATABASE + "api/createViewStates", {
      userId: contactId,
      ViewState: viewStateCookie,
    })
    .catch((error) => {
      console.log(error);
    });

  if (propertyMapRef && propertyMapRef.current) {
    propertyMapRef.current.mapReload();

    propertyMapRef.current.refreshMapProperties();
  }
}
